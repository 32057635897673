/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Divider, Typography } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';
import SearchNotFound from '../../../components/SearchNotFound/SearchNotFound';
import { useAppContext } from '../../../hooks/AppContext';
import { _changeCard } from '../../../DAL/plans';
import { _detailCustomer } from '../../../DAL/customer';

function ManageCard() {
  const { customerDetail, setCustomerDetail } = useAppContext();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadinBtn] = useState(false);
  const [changeCardStripe, setChangeCardStripe] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (elements == null) {
      return;
    }
    setIsLoadinBtn(true);
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement, stripe, 'elements');
    stripe
      .createToken(cardElement)
      .then((payload) => {
        console.log('[token]', payload);
        if (payload.error) {
          enqueueSnackbar(payload.error.message, { variant: 'error' });
        }
        console.log(payload, 'stripe token');
        handleCardAction(payload.token.id, cardElement);
        // setIsLoadingBtn(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadinBtn(false);
      });
  };

  const handleCardAction = (cardToken, cardElement) => {
    changeStripeCard(cardToken, cardElement);
  };

  const changeStripeCard = async (token, cardElement) => {
    const postData = {
      source_token: token,
    };
    const result = await _changeCard(postData);
    console.log(result, 'result');
    if (result.code === 200) {
      setIsLoading(false);
      var customer_result = await _detailCustomer();
      setCustomerDetail({
        ...customerDetail,
        card: customer_result.customer.card,
      });
      setChangeCardStripe(false);
      enqueueSnackbar('Card Changed successfully.', {
        variant: 'success',
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setChangeCardStripe(false);
    setIsLoading(false);
    setIsLoadinBtn(false);
  };

  return (
    <>
      <div className="security-tab">
        <h3 className="h2-font-size" style={{ fontWeight: '600' }}>
          Manage Card
        </h3>
        <Divider sx={{ color: '#f4f4f4' }} className="mb-1" />
        {customerDetail?.subscription_status && customerDetail?.card !== '' ? (
          <>
            <p style={{ color: 'black', fontSize: '18px', marginTop: '2%' }}>Default Card</p>
            <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-2">
              {customerDetail?.subscription_status && (
                <div className="d-flex flex-column  w-100">
                  <Card
                    className="m-0 mb-2 p-2 px-3"
                    sx={{
                      borderRadius: '2px',
                      boxShadow: '0px 1px 3px 2px rgba(90, 114, 123, 0.1)',
                    }}
                  >
                    <div className="d-flex justify-content-between m-1">
                      <div>
                        <Iconify
                          icon={
                            customerDetail?.card_brand === 'visa'
                              ? 'logos:visa'
                              : customerDetail?.card_brand === 'mastercard'
                              ? 'logos:mastercard'
                              : customerDetail?.card_brand === 'discover'
                              ? 'logos:discover'
                              : customerDetail?.card_brand === 'unionoay'
                              ? 'logos:unionpay'
                              : customerDetail?.card_brand === 'diners club'
                              ? 'fa-brands:cc-diners-club'
                              : customerDetail?.card_brand === 'american express'
                              ? 'fontisto:american-express'
                              : customerDetail?.card_brand === 'jcb'
                              ? 'logos:jcb'
                              : 'fluent-mdl2:payment-card'
                          }
                          width={50}
                          height={24}
                        />
                      </div>
                      <div>*** *** **** {customerDetail?.card}</div>
                    </div>
                  </Card>
                  {!changeCardStripe && (
                    <div className="float-right my-2">
                      <Button variant="outlined" size="large" onClick={() => setChangeCardStripe(true)}>
                        Change Card
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div style={{ marginTop: '12%' }}>
            <SearchNotFound
              title={'No Card Added. When you buy a subscription plan, card will show here.'}
              icon={'fluent-mdl2:payment-card'}
            />
          </div>
        )}
        {changeCardStripe && (
          <div className="position-relative">
            <Divider sx={{ color: '#f4f4f4' }} className="my-2" />
            <form onSubmit={handleSubmit}>
              <div className="text-end modalIcon">
                <Close onClick={isLoading ? '' : handleClose} sx={{ cursor: 'pointer', color: 'grey' }} />
              </div>
              <Typography className="text-center" id="spring-modal-title" variant="h5">
                Enter New Card
              </Typography>

              <div className="col-12 mt-3">
                <div className="card p-3">
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          padding: '20px',
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-12 mt-3">
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={isLoadingBtn}>
                  SAVE
                </LoadingButton>
              </div>
              <Typography variant="h6" color={'textSecondary'} className="my-2">
                *You can only have one card in your account. After changing card your future bills will be charged from
                new card.
              </Typography>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default ManageCard;
