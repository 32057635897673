/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Close, Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAppContext } from '../../../hooks/AppContext';
import { _changePassword } from '../../../DAL/customer';
import { _userLogout, _userLogoutFromEverywhere } from '../../../DAL/auth';

// mui modal for email
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 5,
};

function Security() {
  const { customerDetail, setIsLoading } = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  // mui modal
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleClickShowPassword = (key) => {
    if (key === 1) {
      setShowPassword((show) => !show);
    } else if (key === 2) {
      setShowNewPassword((show) => !show);
    } else if (key === 3) {
      setShowConfirmPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenPasswordModal = () => {
    handleClose();
    setOpenPasswordModal(true);
  };
  const handleClosePasswordModal = () => setOpenPasswordModal(false);
  // ---------

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangePassword = async () => {
    setIsLoadingApi(true);
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!newPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar('Please enter new password', { variant: 'error' });
    }

    if (!passwordPattern.test(newPassword)) {
      setIsLoadingApi(false);
      return enqueueSnackbar("Password doesn't meet the criteria", { variant: 'error' });
    }

    if (!confirmPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar('Please enter confirm password', { variant: 'error' });
    }

    if (newPassword !== confirmPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar("Password doesn't match with confirm password", { variant: 'error' });
    }
    const reqObj = {
      old_password: password,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    const response = await _changePassword(reqObj);
    if (response.code === 200) {
      setIsLoadingApi(false);
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
      handleClosePasswordModal();
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      console.log(response.message, 'change password error');
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoadingApi(false);
    }
  };

  const handleLogoutFromEveryWhere = async () => {
    setIsLoadingBtn(true);
    const response = await _userLogoutFromEverywhere();
    if (response.code === 200) {
      localStorage.clear();
      setIsLoading(false);
      setIsLoadingBtn(false);
      navigate('/login');
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      setIsLoadingBtn(true);
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const requirementList = document.querySelectorAll('div.row div.input-message');
  const requirements = [
    { regex: /[a-z]/, index: 0 }, // atleast 1 lowercase
    { regex: /[A-Z]/, index: 1 }, // atleast 1 uppercase
    { regex: /[0-9]/, index: 2 }, // atleast 1 number
    { regex: /.{8,}/, index: 3 }, // minimum 8 char
  ];

  const passwordEventListener = (e) => {
    requirements.forEach((item) => {
      const isValid = item.regex.test(e.target.value);
      const requirementItem = requirementList[item.index];
      console.log(requirementList, 'requirementItemrequirementItem');
      if (isValid) {
        requirementItem.firstElementChild.style.color = '#00a95a';
      } else {
        requirementItem.firstElementChild.style.color = '#ccc';
      }
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  return (
    <>
      <div className="security-tab">
        <h3 className="h2-font-size" style={{ fontWeight: '600' }}>
          Security
        </h3>
        <Divider sx={{ color: '#f4f4f4' }} className="mb-1" />
        <p style={{ fontSize: '20px', color: 'black' }}>Password</p>
        <p style={{ fontSize: '16px', color: '#474b4f' }}>Characteristics of strong password:</p>
        <ul style={{ fontSize: '17px', color: '#474b4f', marginLeft: '20px' }}>
          <li>At least 8 characters. The more characters, the better password.</li>
          <li>A mixture of both uppercase and lowercase letters.</li>
          <li>A mixture of letters and numbers.</li>
          <li>Inclusion of at least one special character, e.g., ! @ # ? *.</li>
        </ul>
        <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-2">
          <Button onClick={() => handleOpenPasswordModal()} size="large" variant="outlined">
            Change Password
          </Button>
        </div>
        <Divider sx={{ color: '#f4f4f4' }} className="my-3" />
        <p style={{ fontSize: '16px', color: 'black' }}>Sign out everywhere</p>
        <p style={{ fontSize: '17px', color: '#474b4f' }}>
          Click here to sign out of Mailcub on all devices including mobile, tablet, desktop and stay secure if you
          signed in from any public devices or suspect unauthorized activity.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }} className="my-2">
          <Button onClick={handleClickOpenDialog} size="large" variant="outlined">
            Sign Out Everywhere
          </Button>
        </div>
      </div>

      {/* logoutfromeverywhere dialog */}
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to signout from everywhere?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              // startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={handleLogoutFromEveryWhere}
            >
              Yes, Continue
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>

      <div className="password-modal">
        <Modal
          open={openPasswordModal}
          onClose={handleClosePasswordModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5>Change your user password</h5>
            <div className="pt-5">
              <div className="form-group w-100 mb-3">
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">Old Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(1)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Old Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="form-group w-100 mb-3">
                {/* <label className="mb-1" htmlFor="exampleInputPassword1">
              New password
              <input
                type="password"
                className="form-control mt-1"
                id="exampleInputPassword1"
                name="password"
                value={newPassword}
                min="8"
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyUp={(e) => passwordEventListener(e)}
              />
            </label> */}
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">New Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={(e) => passwordEventListener(e)}
                  />
                </FormControl>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One lowercase character</p>
                  </div>
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One uppercase character</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One number</p>
                  </div>
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>8 characters minimum</p>
                  </div>
                </div>
              </div>
              <div className="form-group w-100 mb-3">
                {/* <label className="mb-1" htmlFor="exampleInputPassword1">
              Confirm Password
              <input
                type="password"
                className="form-control mt-1"
                id="exampleInputPassword1"
                name="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </label> */}
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(3)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="mt-5" style={{ textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  component="label"
                  style={{
                    padding: '8px 22px',
                    width: 'max-content',
                    marginRight: '10px',
                  }}
                  onClick={() => handleClosePasswordModal()}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoadingApi}
                  loadingPosition="end"
                  endIcon=" "
                  variant="contained"
                  style={{
                    padding: '8px 22px',
                  }}
                  onClick={() => handleChangePassword()}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Security;
