/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';
import { useAppContext } from 'src/hooks/AppContext';
import { DialogContent } from '@mui/material';
import Sidebar from './components/Sidebar';
import Accounts from './components/Accounts';
import Security from './components/Security';
import SubscriptionCard from './components/SubscriptionCard';
import ManageCardWrapper from './components/ManageCardWrapper';

export default function Profile() {
  const { openProfileDialog, setOpenProfileDialog } = useAppContext();
  const [displayComponent, setDisplayComponent] = useState('accounts');
  const handleCloseProfile = () => {
    setOpenProfileDialog(false);
    setDisplayComponent('accounts');
    // console.log(openProfileDialog, 'fghjk');
  };
  // console.log('jygtjhghjgjg');
  return (
    <>
      <Dialog
        open={openProfileDialog}
        onClose={handleCloseProfile}
        PaperProps={{ className: 'dialog-paper' }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ pt: 0, pl: 0, pb: 0, pr: 0, height: '73vh' }}>
          <IconButton
            onClick={handleCloseProfile}
            style={{ color: 'grey', position: 'absolute', zIndex: '1', right: '2%' }}
          >
            <Close />
          </IconButton>
          <div className="d-flex" style={{ position: 'relative' }}>
            <Sidebar displayComponent={displayComponent} setDisplayComponent={setDisplayComponent} />
            {displayComponent === 'accounts' && (
              <div className="dialog-right-column" style={{ position: 'absolute', left: '500px', right: '0' }}>
                <Accounts />
              </div>
            )}
            {displayComponent === 'subscription' && (
              <div className="dialog-right-column" style={{ position: 'absolute', left: '500px', right: '0' }}>
                <SubscriptionCard />
              </div>
            )}
            {displayComponent === 'card' && (
              <div className="dialog-right-column" style={{ position: 'absolute', left: '500px', right: '0' }}>
                <ManageCardWrapper />
              </div>
            )}
            {displayComponent === 'security' && (
              <div className="dialog-right-column" style={{ position: 'absolute', left: '500px', right: '0' }}>
                <Security />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
