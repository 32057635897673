/* eslint-disable react/prop-types */
import { useContext, createContext, useState } from 'react';

const Context = createContext();

const AppContext = ({ children }) => {
  const [customerDetail, setCustomerDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);

  const bundle = {
    customerDetail,
    setCustomerDetail,
    isLoading,
    setIsLoading,
    openProfileDialog,
    setOpenProfileDialog,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => useContext(Context);
