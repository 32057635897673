/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */

import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  styled,
  Dialog,
  DialogTitle,
  DialogActions,
  Modal,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  FormControl,
  DialogContent,
  DialogContentText,
  Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, Delete } from '@mui/icons-material';
import moment from 'moment';
// api
import { _getTransactions } from '../../DAL/transactions';
// components
import Scrollbar from '../../components/scrollbar';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
// sections
import { UserListHead } from '../../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'planId', label: '#', alignRight: false, center: true },
  { id: 'customer_name', label: 'Customer Name', alignCenter: true },
  { id: 'name', label: 'Plan Name', alignCenter: true },
  { id: 'amount', label: 'Amount', alignCenter: true },
  { id: 'currency', label: 'Currency', alignCenter: true },
  { id: 'card', label: 'Card', alignCenter: true },
  { id: 'card_brand', label: 'Card Brand', alignCenter: true },
  { id: 'transaction_date', label: 'Transaction Date', alignCenter: true },
  { id: 'transaction_status', label: 'Transaction Status', alignCenter: true },
  { id: 'createdAt', label: 'Created At', alignCenter: true },
  { id: 'updatedAt', label: 'Updated At', alignCenter: true },
  { id: 'hosted_invoice_url', label: 'Invoice URL', alignCenter: true },
  { id: 'invoice_pdf', label: 'Invoice PDF', alignCenter: true },
  // { id: 'action', label: 'Action', alignRight: true },
];

function Transactions() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState('lg');
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(null);
  const [rowId, setRowId] = useState();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [transaction, setTransaction] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  // functions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('handleChangeRowsPerPage', event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setIsLoading(true);
  };

  // api
  const getAllTransactions = async () => {
    setIsLoading(true);
    const response = await _getTransactions(page, rowsPerPage);
    if (response.code === 200) {
      setIsLoading(false);
      setTransaction(response.transactions);
      setTotalCount(response.transaction_count ? response.transaction_count : 0);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(response.message, 'transaction listing error');
    }
  };

  // date & time formate
  const formateDate = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedDate = isoDate.toLocaleDateString();
    return formattedDate;
  };

  const formateTime = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedTime = isoDate.toLocaleTimeString();
    return formattedTime;
  };

  useEffect(() => {
    getAllTransactions();
  }, [page, rowsPerPage]);

  useEffect(() => {
    setIsLoading(true);
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Transactions </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: '800' }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={transaction?.length}
                    numSelected={selected?.length}
                  />
                  <TableBody>
                    {transaction &&
                      transaction?.map((row, index) => {
                        const {
                          _id,
                          customer_name,
                          amount,
                          currency,
                          card,
                          card_brand,
                          transaction_date,
                          transaction_status,
                          hosted_invoice_url,
                          invoice_pdf,
                          plan_id,
                          createdAt,
                          updatedAt,
                        } = row;
                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                            <TableCell>{page * rowsPerPage + (index + 1)}</TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {customer_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {plan_id.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {amount}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {currency ? 'USD' : ''}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {card}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {card_brand ? 'VISA' : ''}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {transaction_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 180 }}
                            >
                              <Label
                                style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                color={
                                  (transaction_status === 'payment_intent.succeeded' && 'success') ||
                                  (transaction_status === 'payment_intent.payment_failed' && 'error') ||
                                  (transaction_status === 'payment_intent.requires_action' && 'warning') ||
                                  (transaction_status === 'payment_intent.canceled' && 'error') ||
                                  (transaction_status === 'customer.subscription.deleted' && 'error')
                                }
                              >
                                {transaction_status === 'payment_intent.succeeded'
                                  ? 'Succeed'
                                  : transaction_status === 'payment_intent.payment_failed'
                                  ? 'Failed'
                                  : transaction_status === 'payment_intent.requires_action'
                                  ? 'Pending'
                                  : transaction_status === 'payment_intent.canceled'
                                  ? 'Cancel'
                                  : 'Delete'}
                              </Label>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {moment(createdAt).format('lll')}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {moment(updatedAt).format('lll')}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Button>
                                <a className="transaction-tag" href={hosted_invoice_url} target="_blank">
                                  Preview
                                </a>
                              </Button>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Button>
                                <a className="transaction-tag" href={invoice_pdf}>
                                  Download PDF
                                </a>
                              </Button>
                            </TableCell>
                            {/* <TableCell align="right">
                                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e,_id)}>
                                                <Iconify icon={'eva:more-vertical-fill'} />
                                            </IconButton>
                                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {transaction?.length == 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph isLoading="true">
                              No Data Found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            className="domain-table-pagination"
            rowsPerPageOptions={[5, 25, 50, 100, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}

export default Transactions;
