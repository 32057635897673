import { invokeApi } from '../utils/invokeApi';

export const _addSupportTicket = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_support_ticket`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _listSupportTicket = async (page, limit) => {
  const requestObj = {
    path: `api/support_ticket/active_support_ticket?page=${page}&limit=${limit}`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const _detailSupportTicket = async (id) => {
  const requestObj = {
    path: `api/support_ticket/detail_support_ticket/${id}`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _deleteSupportTicket = async (id) => {
  const requestObj = {
    path: `api/support_ticket/delete_support_ticket/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _closeSupportTicket = async (id) => {
  const requestObj = {
    path: `api/support_ticket/close_support_ticket/${id}`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _addSupportTicketComment = async (data) => {
  const requestObj = {
    path: `api/support_ticket_comment/add_support_ticket_comment`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _deleteSupportTicketComment = async (id) => {
  const requestObj = {
    path: `api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};
