/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../iconify';

function UpgradeDialog({
  openUpgradeDialog,
  handleCloseDialog,
  isLoadingBtn,
  handleNavigate,
  upgradeHeading,
  upgradeParagraph,
  upgradeIcon,
}) {
  return (
    <>
      <Dialog
        open={openUpgradeDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent id="alert-dialog-title">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Iconify icon={upgradeIcon} color="#FFD700" height="30%" width="30%" />
            <div>
              <h3 className="text-center mt-4">{upgradeHeading}</h3>
              <p className="text-center mt-3" style={{ fontSize: '20px' }}>
                {upgradeParagraph}
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '0 32px 32px' }}>
          <Button
            className="close-btn"
            startIcon={<Close />}
            variant="outlined"
            onClick={handleCloseDialog}
            size="small"
            sx={{ mr: 1 }}
          >
            <span style={{ fontSize: '15px', marginLeft: '10px' }}>Cancel</span>
          </Button>
          <LoadingButton
            loading={isLoadingBtn}
            loadingPosition="start"
            startIcon={<Iconify icon="ph:star-fill" color="#eba100" height="17px" width="17px" />}
            variant="contained"
            size="small"
            onClick={handleNavigate}
          >
            <span style={{ fontSize: '15px', marginLeft: '10px' }}>Upgrade</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpgradeDialog;
