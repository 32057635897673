/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Dialog, DialogActions, DialogTitle, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useAppContext } from '../../hooks/AppContext';
import { useSnackbar } from 'notistack';
import { _userLogout } from '../../DAL/auth';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { Close, Delete } from '@mui/icons-material';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  customclass: PropTypes.string,
  isMouseEnter: PropTypes.bool,
  isDesktop: PropTypes.func,
};

export default function NavSection({ customclass, isMouseEnter = null, isDesktop, data = [], ...other }) {
  // console.log(data, 'dattaaaa');
  return (
    <Box className={`parent-${customclass}`} {...other}>
      <List className={customclass} disablePadding sx={{ p: 1 }}>
        {data().map((item) => (
          <NavItem key={item.title} item={item} isDesktop={isDesktop} isMouseEnter={isMouseEnter} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isMouseEnter: PropTypes.bool,
  isDesktop: PropTypes.func,
};

function NavItem({ item, isMouseEnter, isDesktop }) {
  const { setIsLoading } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { title, path, icon, info, logout } = item;
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleLogout = async () => {
    setIsLoadingBtn(true);
    const response = await _userLogout();
    if (response.code === 200) {
      localStorage.clear();
      setIsLoadingBtn(false);
      navigate('/login');
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      setIsLoadingBtn(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (!logout) {
    return (
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        {(isMouseEnter || !isDesktop) && (
          <ListItemText className="animation-box-LTR" disableTypography primary={title} />
        )}

        {info && info}
      </StyledNavItem>
    );
  } else {
    return (
      <>
        <div className="logout-btn" onClick={handleClickOpenDialog}>
          <StyledNavItem component={RouterLink}>
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            {(isMouseEnter || !isDesktop) && (
              <ListItemText className="animation-box-LTR" disableTypography primary={title} />
            )}
            {info && info}
          </StyledNavItem>
        </div>

        {/* dialog for logout */}
        <div>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
              {'Do you want to logout?'}
            </DialogTitle>
            <DialogActions style={{ padding: '0 32px 32px' }}>
              <Button
                startIcon={<Close />}
                variant="outlined"
                onClick={handleCloseDialog}
                className="close-btn"
                size="small"
              >
                Cancel
              </Button>
              <LoadingButton loading={isLoadingBtn} variant="contained" size="small" onClick={handleLogout}>
                Yes, Continue
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}
