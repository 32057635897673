import { invokeApi } from "../utils/invokeApi";

export const _detailCustomer = async () => {
    const requestObj = {
      path: `api/customer/detail_customer`,
      method: "GET",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    };
    return invokeApi(requestObj);
  };

export const _editCustomer = async (data) => {
    const requestObj = {
      path: `api/customer/edit_customer`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

export const _changeEmail = async (data) => {
    const requestObj = {
      path: `api/app_api/change_email`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _changePassword = async (data) => {
    const requestObj = {
      path: `api/app_api/change_password`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };