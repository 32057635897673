/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Avatar, Box, Button, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { s3baseUrl } from '../../../config/config';
import { useAppContext } from '../../../hooks/AppContext';
import { _detailCustomer, _editCustomer } from '../../../DAL/customer';
import { _fileUpload } from '../../../DAL/fileUpload';

const Accounts = () => {
  const { customerDetail, setCustomerDetail } = useAppContext();
  // console.log(customerDetail, 'customerDetailcustomerDetail');
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState(customerDetail?.first_name);
  const [lastName, setLastName] = useState(customerDetail?.last_name);
  const [email, setEmail] = useState(customerDetail?.user?.email);

  const [profileImage, setProfileImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  // const getCustomerDetail = async () => {
  //   setIsLoading(true);
  //   const response = await _detailCustomer();
  //   if (response.code === 200) {
  //     setIsLoading(false);
  //     setCustomerDetail(response.customer);
  //   } else {
  //     console.log(`Error: ${response.code}`);
  //     enqueueSnackbar(response.message, { variant: 'error' });
  //   }
  // };

  // useEffect(() => {
  //   getCustomerDetail();
  // }, []);

  const handleUploadImage = async (e) => {
    console.log('imasdasdasd', e.target.files[0]);
    setProfileImage(e.target.files[0]);
  };

  const handleEditProfile = async (e) => {
    e.preventDefault();
    setIsLoadingProfile(true);
    const reqObj = {
      file: profileImage,
      directory: 'profileImage',
    };
    if (profileImage) {
      const response = await _fileUpload(reqObj);
      if (response.code === 200) {
        const reqObj = {
          first_name: firstName,
          last_name: lastName,
          profile_image: response.path,
          email,
        };
        const result = await _editCustomer(reqObj);
        if (result.code === 200) {
          setIsLoadingProfile(false);
          setCustomerDetail(result.data);
          enqueueSnackbar(result.message, { variant: 'success' });
        } else {
          enqueueSnackbar(result.message, { variant: 'error' });
          console.log(`Error Profile Edit: ${result.code}`);
          setIsLoadingProfile(false);
        }
      } else {
        console.log(`Error Image Upload: ${response.code}`);
        enqueueSnackbar(response.message, { variant: 'error' });
        setIsLoadingProfile(false);
      }
    } else {
      const reqObj = {
        first_name: firstName,
        last_name: lastName,
        profile_image: customerDetail?.profile_image,
        email,
      };
      const result = await _editCustomer(reqObj);
      if (result.code === 200) {
        setIsLoadingProfile(false);
        setCustomerDetail(result.data);
        enqueueSnackbar(result.message, { variant: 'success' });
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        setIsLoadingProfile(false);
        console.log(`Error Profile Edit: ${result.code}`);
      }
    }
  };

  useEffect(() => {
    if (profileImage) {
      console.log(profileImage, 'profileImageprofileImage');
      setPreviewImage(URL.createObjectURL(profileImage));
    }
  }, [profileImage]);

  useEffect(() => {
    setFirstName(customerDetail?.first_name);
    setLastName(customerDetail?.last_name);
    setEmail(customerDetail?.user?.email);
    if (customerDetail?.profile_image) {
      setPreviewImage(s3baseUrl + customerDetail?.profile_image);
    }
  }, [customerDetail]);

  return (
    <>
      <div style={{ padding: '20px 40px' }}>
        <h3 className="h2-font-size" style={{ fontWeight: '600' }}>
          My Profile
        </h3>
        <div style={{ padding: '60px 40px', backgroundColor: '#fff', borderRadius: '7px' }}>
          <div>
            <h5>Profile Photo</h5>
            <p className="mt-1 mb-4">You can upload your own photo or use your profile picture from Gravatar.</p>
            <div className="d-flex align-items-center">
              <div>
                {previewImage ? (
                  <img src={previewImage} alt="profile-img" width="120" height="120" />
                ) : (
                  <Avatar
                    alt="profile image"
                    style={{
                      width: '120px',
                      height: '120px',
                      borderRadius: '0',
                    }}
                  />
                )}
              </div>
              <div>
                <Button
                  variant="outlined"
                  component="label"
                  style={{
                    marginLeft: '30px',
                    padding: '8px 22px',
                  }}
                >
                  <input
                    hidden
                    type="file"
                    accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                    onChange={(e) => handleUploadImage(e)}
                  />
                  Upload photo
                </Button>
              </div>
            </div>
          </div>
          {/* <Divider className="mt-3 mb-5" style={{ color: '#d6d6d6' }} /> */}
          <div>
            <h5 className="mt-4">Basic Information</h5>
            <div className="mt-3" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group w-100 me-1 mb-3">
                <label className="mb-1" htmlFor="exampleInputFname1">
                  First Name
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="exampleInputFname1"
                    aria-describedby="fnameHelp"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </label>
              </div>
              <div className="form-group w-100 ms-1 mb-3">
                <label className="mb-1" htmlFor="exampleInputLname1">
                  Last Name
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="exampleInputLname1"
                    aria-describedby="lnameHelp"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div>
              <div className="form-group w-100 mb-3 me-1">
                <label className="mb-1" htmlFor="exampleInputEmail1">
                  Email address
                  <input
                    type="email"
                    className="form-control mt-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <Divider className="mt-4 mb-5" style={{ color: '#d6d6d6' }} />

          <div className="d-flex justify-content-end">
            <LoadingButton
              loading={isLoadingProfile}
              loadingPosition="end"
              endIcon=" "
              variant="contained"
              style={{
                padding: '8px 22px',
              }}
              onClick={(e) => handleEditProfile(e)}
            >
              Save Changes
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
