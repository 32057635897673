import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';
import logo from "../../assets/images/logo.png";
import { _resetPassword } from '../../DAL/auth';

const ResetPassword = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [userEmail, setUserEmail] = useState(localStorage.getItem("user-email"))
  const [userPassword, setUserPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault(); 
  };

  const handleSendResetLink = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true)
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordPattern.test(userPassword)) {
      setIsLoadingBtn(false)
      enqueueSnackbar("Password doesn't meet the criteria", { variant: "error" })
      return
    }
    const response = await _resetPassword({ email: userEmail, password: userPassword, confirm_password: confirmPassword })
    if (response.code === 200) {
      setIsLoadingBtn(false)
      enqueueSnackbar(response.message, { variant: "success" })
      navigate("/login")
    }
    else {
      setIsLoadingBtn(false)
      enqueueSnackbar(response.message, { variant: "error" })
      console.log(`Error: ${response.code}`);
    }
  }

  const requirementList = document.querySelectorAll("div.row div.input-message");
  const requirements = [
    { regex: /[a-z]/, index: 0 }, // atleast 1 lowercase
    { regex: /[A-Z]/, index: 1 }, // atleast 1 uppercase
    { regex: /[0-9]/, index: 2 }, // atleast 1 number
    { regex: /.{8,}/, index: 3 }, // minimum 8 char
  ]

  const passwordEventListener = (e) => {
    requirements.forEach(item => {
      const isValid = item.regex.test(e.target.value);
      const requirementItem = requirementList[item.index];
      console.log(requirementList, "requirementItemrequirementItem")
      if (isValid) {
        requirementItem.firstElementChild.style.color = "#0c2e60"
      }
      else {
        requirementItem.firstElementChild.style.color = "#ccc"
      }
    })
  }

  return (
    // 3
    <div className="container-fluid">
      <div className="row" style={{ height: "100vh" }}>
        <div className="col- col-lg-12 col-xl-8 py-4 d-flex flex-column">
          <img src={logo} alt="logo" className="d-block ps-5" width={200} />
          <div
            className="d-flex flex-column align-items-center"
            style={{ padding: "60px 0" }}
          >
            <div className="text-left custom-width w-50 mb-3">
              <a href="/login"><i className="fa-solid fa-chevron-left fa-2xs" /> Go back</a>
              <h1 className="mb-3">Reset password</h1>
              <form className="form-label" onSubmit={handleSendResetLink}>
                <div className="form-group mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="E-mail Address"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="exampleInputPassword1">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      name="password"
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                      onKeyUp={(e) => passwordEventListener(e)}
                    />
                  </FormControl>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-baseline input-message">
                      <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: "#ccc" }} />
                      <p>One lowercase character</p>
                    </div>
                    <div className="d-flex align-items-baseline input-message">
                      <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: "#ccc" }} />
                      <p>One uppercase character</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-baseline input-message">
                      <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: "#ccc" }} />
                      <p>One number</p>
                    </div>
                    <div className="d-flex align-items-baseline input-message">
                      <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: "#ccc" }} />
                      <p>8 characters minimum</p>
                    </div>
                  </div>


                </div>
                <div className="form-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Repeat password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoadingBtn}
                  loadingPosition="start"
                  startIcon=" "
                  variant="contained"
                  style={{ padding: ".75rem 1.5rem" }}
                >
                  Reset Password
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-4 right-col" />
      </div>
    </div>
  )
}

export default ResetPassword