/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useAppContext } from '../../hooks/AppContext';
import { _detailCustomer } from '../../DAL/customer';
//
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 35;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const token = localStorage.getItem('token');

  const { setCustomerDetail, isLoading, setIsLoading } = useAppContext();

  const getCustomerDetail = async () => {
    setIsLoading(true);
    const response = await _detailCustomer();
    if (response.code === 200) {
      setCustomerDetail(response.customer);
      setIsLoading(false);
    } else {
      console.log(`Error: ${response.code}`);
    }
  };

  useEffect(() => {
    getCustomerDetail();
  }, []);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <StyledRoot>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Header onOpenNav={() => setOpen(true)} />

          <Nav openNav={open} onCloseNav={() => setOpen(false)} />

          <Main>
            <Outlet />
          </Main>
        </>
      )}
    </StyledRoot>
  );
}
