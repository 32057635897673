/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useAppContext } from '../../hooks/AppContext';
import logomailcub from '../../assets/images/logomailcub.png';
import { _userLogin } from '../../DAL/auth';

const Signin = () => {
  const { setIsLoading } = useAppContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoadingBtn(true);
    const checkBox = rememberMe === true ? rememberMe : '';
    console.log(checkBox, 'checkBoxcheckBox');
    const reqObj = {
      email,
      password,
      type: 1,
    };
    const response = await _userLogin(reqObj);
    if (response.code === 200) {
      setIsLoadingBtn(false);
      setIsLoading(false);
      localStorage.setItem('token', response.token);
      localStorage.setItem('key', response.data.key);
      enqueueSnackbar(response.message, { variant: 'success' });
      navigate('/');
    } else {
      setIsLoadingBtn(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ height: '100vh' }}>
        <div className="col- col-lg-12 col-xl-7 py-4 d-flex flex-column justify-content-center">
          <img src={logomailcub} alt="logo" className="d-block ps-5" width={200} />
          <div className="d-flex flex-column align-items-center" style={{ padding: '60px 0' }}>
            <div className="text-left custom-width w-50 mb-3">
              <h1 className="mb-3">Sign in to your Mailcub account</h1>
              <p className="mb-4">
                Don't have an account yet?{' '}
                <a href="/signup" style={{ color: ' #00a95a' }}>
                  <strong>Sign up</strong>
                </a>
              </p>
              <form className="form-label" onSubmit={handleLogin}>
                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-group mb-3">
                  {/* <label htmlFor="exampleInputPassword1">Password
                  </label> */}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="exampleInputPassword1">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="form-group form-check">
                    {/* <label className="form-check-label" htmlFor="exampleCheck1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        value={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      Remember me
                    </label> */}
                  </div>
                  <a href="/forgot-password" style={{ color: ' #00a95a' }}>
                    <strong>Forgot your password?</strong>
                  </a>
                </div>
                {/* <button type="submit"
                  style={{ backgroundColor: "#0c2e60", borderColor: "#0c2e60", padding: ".75rem 1.5rem" }}
                  className="btn btn-primary w-100"
                >
                  Sign in
                </button> */}
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoadingBtn}
                  loadingPosition="start"
                  startIcon=" "
                  variant="contained"
                  style={{ padding: '.75rem 1.5rem' }}
                >
                  Sign in
                </LoadingButton>
              </form>
            </div>
            {/* <div className="d-flex align-items-center mb-3">
              <hr className="custom-line me-3 border-secondary" />
              <p>or</p>
              <hr className="custom-line ms-3 border-secondary" />
            </div>
            <div className="w-50 custom-width d-flex justify-content-between">
              <a
                href=""
                style={{ textDecoration: "none" }}
                className="w-100 d-flex justify-content-center me-2 px-5 py-2 border border-1 rounded"
              >
                <img
                  className="me-2"
                  src={google}
                  alt="google-icon"
                  width={18}
                />
                Google
              </a>
              <a
                href=""
                style={{ textDecoration: "none" }}
                className="w-100 d-flex justify-content-center ms-2 px-5 py-2 border border-1 rounded"
              >
                <img
                  className="me-2"
                  src={microsoft}
                  alt="google-icon"
                  width={18}
                />
                Microsoft
              </a>
            </div> */}
          </div>
        </div>
        <div className="col-xl-5 px-0">
          <div className="right-col" />
        </div>
      </div>
    </div>
  );
};

export default Signin;
