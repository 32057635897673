/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAppContext } from '../../hooks/AppContext';
import logomailcub from '../../assets/images/logomailcub.png';
import google from '../../assets/images/google.svg';
import microsoft from '../../assets/images/microsoft.svg';
import check from '../../assets/images/check.svg';
import { _userSignup } from '../../DAL/auth';

const Signup = () => {
  const { setIsLoading } = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e) => {
    const { target } = e;
    setInputs((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true);
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordPattern.test(inputs.password)) {
      setIsLoadingBtn(false);
      enqueueSnackbar("Password doesn't meet the criteria", { variant: 'error' });
      return;
    }
    setIsLoading(true);
    const reqObj = {
      first_name: inputs.firstName,
      last_name: inputs.lastName,
      email: inputs.email,
      password: inputs.password,
      industry_type: 'Educational',
      customer_type: 'app',
    };
    const response = await _userSignup(reqObj);
    if (response.code === 200) {
      setIsLoadingBtn(false);
      localStorage.setItem('token', response.customer.token);
      localStorage.setItem('key', response.customer.key);
      enqueueSnackbar(response.message, { variant: 'success' });
      setInputs({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
      });
      navigate('/dashboard');
    } else {
      setIsLoadingBtn(false);
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const requirementList = document.querySelectorAll('div.row div.input-message');
  const requirements = [
    { regex: /[a-z]/, index: 0 }, // atleast 1 lowercase
    { regex: /[A-Z]/, index: 1 }, // atleast 1 uppercase
    { regex: /[0-9]/, index: 2 }, // atleast 1 number
    { regex: /.{8,}/, index: 3 }, // minimum 8 char
  ];

  const passwordEventListener = (e) => {
    requirements.forEach((item) => {
      const isValid = item.regex.test(e.target.value);
      const requirementItem = requirementList[item.index];
      if (isValid) {
        requirementItem.firstElementChild.style.color = '#00a95a';
      } else {
        requirementItem.firstElementChild.style.color = '#ccc';
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: '100vh' }}>
          <div className="col- col-lg-12 col-xl-7 py-5 d-flex flex-column justify-content-center">
            <img src={logomailcub} alt="logo" className="d-block ps-5" width={200} />
            <div className="d-flex flex-column align-items-center" style={{ padding: '60px 0' }}>
              <div className="text-left custom-width w-50 mb-3">
                <h1 className="mb-3" style={{ fontSize: '30px' }}>
                  Get started with a Forever Free plan
                </h1>
                <p className="mb-4">Sign up in seconds. No credit card required.</p>
                <form className="form-label" method="get" onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      value={inputs.firstName}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      value={inputs.lastName}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      name="email"
                      variant="outlined"
                      value={inputs.email}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      value={inputs.phone}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </div>
                  <div className="form-group mb-3">
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel htmlFor="exampleInputPassword1">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        name="password"
                        value={inputs.password}
                        onChange={handleInputChange}
                        onKeyUp={(e) => passwordEventListener(e)}
                      />
                    </FormControl>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex align-items-baseline input-message">
                        <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                        <p>One lowercase character</p>
                      </div>
                      <div className="d-flex align-items-baseline input-message">
                        <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                        <p>One uppercase character</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-baseline input-message">
                        <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                        <p>One number</p>
                      </div>
                      <div className="d-flex align-items-baseline input-message">
                        <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                        <p>8 characters minimum</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <p>
                      By clicking, you agree to <a href="">Terms of Use</a>, <a href="">Privacy Policy</a> and{' '}
                      <a href="">Anti-Spam Policy</a>.
                    </p>
                  </div>
                  {/* <button
                    type="submit"
                    style={{
                      backgroundColor: "#0c2e60",
                      borderColor: "#0c2e60",
                      padding: ".75rem 1.5rem",
                    }}
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                  >
                    Create my account
                  </button> */}
                  <LoadingButton
                    type="submit"
                    fullWidth
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon=" "
                    variant="contained"
                    style={{ padding: '.75rem 1.5rem' }}
                  >
                    Create my account
                  </LoadingButton>
                </form>
              </div>
              {/* <div className="d-flex align-items-center mb-4">
                <hr className="custom-line me-3 border-secondary" />
                <p>or</p>
                <hr className="custom-line ms-3 border-secondary" />
              </div>
              <div className="w-50 custom-width">
                <a
                  href=""
                  style={{ textDecoration: "none" }}
                  className="w-100 d-flex justify-content-center mb-4 px-5 py-2 border border-1 rounded"
                >
                  <img
                    className="me-2"
                    src={google}
                    alt="google-icon"
                    width={18}
                  />
                  Sign up with Google
                </a>
                <a
                  href=""
                  color="secondary"
                  style={{ textDecoration: "none" }}
                  className="w-100 d-flex justify-content-center px-5 py-2 border border-1 rounded"
                >
                  <img
                    className="me-2"
                    src={microsoft}
                    alt="google-icon"
                    width={18}
                  />
                  Sign up with Microsoft
                </a>
              </div> */}
            </div>
          </div>
          <div
            className="col- col-lg-12 col-xl-5 d-flex flex-column justify-content-center "
            style={{ backgroundColor: '#cef3e1' }}
          >
            <div className="signup-right-col">
              <h1>Try Advanced features htmlFor 30 days</h1>
              <p>Your 30-day trial of Advanced features includes:</p>
              <ul className="signup-list mt-5">
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>Access to premium features</h6>
                    <p>Live Chat, template library, auto resend, promotion pop-ups, AI writing assistant and more</p>
                  </div>
                </li>
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>Access to main features</h6>
                    <p>Email automation, landing pages, website builder and more</p>
                  </div>
                </li>
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>Up to 1,000 subscribers</h6>
                  </div>
                </li>
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>Send up to 12,000 emails per month</h6>
                  </div>
                </li>
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>24/7 live chat support</h6>
                  </div>
                </li>
                <li className="d-flex align-items-start">
                  <img src={check} alt="ok" width={20} />
                  <div>
                    <h6>Upgrade anytime</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
