/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Skeleton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { useSnackbar } from 'notistack';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import Label from '../components/label';
import { _dashboard } from '../DAL/dashboard';
import { _detailCustomer } from '../DAL/customer';

// components
import SvgColor from '../components/svg-color';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardDetail, setDashboardDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState('lg');

  const getDashboardDetail = async () => {
    setIsLoading(true);
    const response = await _dashboard();
    if (response.code === 200) {
      setIsLoading(false);
      console.log(response, 'dashboard--');
      setDashboardDetail(response.data);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.code, { variant: 'error' });
      console.log(`Error: ${response.code}`);
    }
  };

  useEffect(() => {
    getDashboardDetail();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" className="mb-5">
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Domains"
              isLoading={isLoading}
              total={dashboardDetail?.total_domains ? dashboardDetail?.total_domains : '0'}
              color="warning"
              icon={'gridicons:domains'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Emails"
              isLoading={isLoading}
              total={dashboardDetail?.total_emails ? dashboardDetail?.total_emails : '0'}
              color="info"
              icon={'dashicons:email-alt'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Emails Bounced"
              isLoading={isLoading}
              total={dashboardDetail?.total_bounced_emails ? dashboardDetail?.total_bounced_emails : '0'}
              color="error"
              icon={'material-symbols:block'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Sent Emails"
              isLoading={isLoading}
              total={dashboardDetail?.total_sent_emails ? dashboardDetail?.total_sent_emails : '0'}
              color="secondary"
              icon={'mdi:email-check-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Account Health"
              isLoading={isLoading}
              total={
                dashboardDetail?.account_status === 'healthy'
                  ? 'Healthy'
                  : dashboardDetail?.account_status === 'paused'
                  ? 'Paused'
                  : 'Disabled'
              }
              color={
                dashboardDetail?.account_status === 'healthy'
                  ? 'success'
                  : dashboardDetail?.account_status === 'paused'
                  ? 'warning'
                  : 'error'
              }
              icon={
                dashboardDetail?.account_status === 'healthy'
                  ? 'ion:cloud-done-outline'
                  : dashboardDetail?.account_status === 'paused'
                  ? 'zondicons:pause-outline'
                  : 'octicon:stop-16'
              }
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}
          {/* {console.log(dashboardDetail?.total_sent_emails, 'aaaaaaaaaaaaaaa')}
          {console.log(dashboardDetail?.total_bounced_emails, 'aaaaaaaaaacccccccccccc')} */}
          {dashboardDetail && (
            <Grid item xs={12} md={12} lg={12}>
              <AppCurrentVisits
                title="Current Status"
                subheader={
                  !(dashboardDetail.total_sent_emails == 0 && dashboardDetail.total_bounced_emails == 0) ||
                  !(dashboardDetail.total_sent_emails == 0)
                }
                chartData={[
                  { label: 'Sent Emails', value: dashboardDetail?.total_sent_emails },
                  { label: 'Emails Bounced', value: dashboardDetail?.total_bounced_emails },
                  // { label: 'Bounced Rate', value: dashboardDetail?.bounce_rate },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.error.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                ]}
              />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
