/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
// component
import { useAppContext } from '../../../hooks/AppContext';
import SvgColor from '../../../components/svg-color';

const icon = (name, color) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, bgcolor: color ?? 'currentColor' }} />
);
function NavConfigSecondary() {
  // ----------------------------------------------------------------------

  const { customerDetail } = useAppContext();
  // console.log(customerDetail, 'htdhtfjhydht');
  return [
    {
      title: customerDetail?.plan?.name === 'Basic' ? 'Choose a Plan' : customerDetail?.plan?.name,
      path: '/plans',
      icon: customerDetail?.plan?.name === 'Basic' ? icon('ic_plan') : icon('star-fill', '#eba100'),
    },
    {
      title: 'Support Tickets',
      path: '/support-ticket',
      icon: icon('ic_support'),
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: icon('ic_settings'),
    },
    {
      title: 'Logout',
      icon: icon('logout'),
      logout: true,
    },
  ];
}

export default NavConfigSecondary;
