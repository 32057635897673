/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'notistack';
import { _getDomainList } from '../../DAL/domain';
import { s3baseUrl } from '../../config/config';
import { _fileUpload } from '../../DAL/fileUpload';
import { _sendEmail } from '../../DAL/sendEmail';
import Scrollbar from '../../components/scrollbar';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function SendEmail(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const [emailList, setEmailList] = useState();
  const [domainList, setDomainList] = useState();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [attachmentData, setAttachmentData] = useState();
  const [windowWidth, setWindowWidth] = useState('lg');
  const [emailError, setEmailError] = useState(false);
  // handle change for send email select modal
  const handleChange = (event) => {
    setSelectedDomain(event.target.value);
  };
  // ------------------------------------

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true);
    if (!attachmentData) {
      const emailFrom = `${fromEmail}@${selectedDomain}`;
      const reqObj = {
        email_from: emailFrom,
        reciever: toEmail,
        subject,
        text: bodyMessage,
      };
      const response = await _sendEmail(reqObj);
      if (response.code === 200) {
        setIsLoadingBtn(false);
        setFromEmail('');
        setToEmail('');
        setSubject('');
        setBodyMessage('');
        setSelectedDomain('');
        console.log('===Response===', response);
        enqueueSnackbar(response.message, { variant: 'success' });
      } else {
        setIsLoadingBtn(false);
        console.log('===Response===', response.code);
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } else {
      const reqObj = {
        file: attachmentData,
        directory: 'email-attachment',
      };
      const uploadResponse = await _fileUpload(reqObj);
      if (uploadResponse.code === 200) {
        const emailFrom = `${fromEmail}@${selectedDomain}`;
        const reqObj = {
          email_from: emailFrom,
          reciever: toEmail,
          subject,
          text: bodyMessage,
          attachment: s3baseUrl + uploadResponse.path,
        };
        const response = await _sendEmail(reqObj);
        if (response.code === 200) {
          setIsLoadingBtn(false);
          setFromEmail('');
          setToEmail('');
          setSubject('');
          setBodyMessage('');
          setAttachmentData([]);
          console.log('===Response===', response);
          enqueueSnackbar(response.message, { variant: 'success' });
        } else {
          setIsLoadingBtn(false);
          console.log('===Response===', response.code);
          enqueueSnackbar(response.message, { variant: 'error' });
          setFromEmail('');
          setToEmail('');
          setSubject('');
          setBodyMessage('');
          setAttachmentData([]);
        }
      } else {
        enqueueSnackbar(uploadResponse.message, { variant: 'error' });
        setIsLoadingBtn(false);
        setAttachmentData([]);
      }
    }
  };

  const getDomainList = async () => {
    // setIsLoading(true)
    const response = await _getDomainList();
    if (response.code === 200) {
      console.log('domain-list', response.data.domains);
      const verifiedDomains = response?.data?.domains.filter((val) => val.verification_status === true);
      setDomainList(verifiedDomains);
    }
  };

  const handleInputChange = (event) => {
    const enteredValue = event.target.value;
    // Extract domain name without '@' symbol
    const domainName = enteredValue.split('@')[0]; // Get the part before '@'
    setFromEmail(domainName);
  };

  useEffect(() => {
    getDomainList();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Send Email </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Send Email
          </Typography>
        </Stack>
        {/* <Card className="p-2"> */}
        {/* <Scrollbar> */}
        <div>
          <Typography id="modal-modal-title" className="mb-4" variant="h6" component="h2">
            Send your email
          </Typography>
          <form className="form-label" onSubmit={handleSendEmail}>
            <div className="mb-3">
              <div className="row mb-4">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Domain</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="mb-3"
                      value={selectedDomain}
                      label="Select Domain"
                      onChange={handleChange}
                    >
                      {domainList?.map((val, index) => (
                        <MenuItem key={index} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="mb-3 d-flex align-items-baseline">
                    <TextField
                      fullWidth
                      required
                      type="text"
                      id="outlined-required"
                      className="me-1"
                      label="Email From:"
                      value={fromEmail}
                      error={emailError}
                      helperText={
                        emailError ? `Email should not contain @ or your domain name and should be alphanumeric` : null
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value.includes('@') ||
                          value.includes('.com') ||
                          value.includes('.co') ||
                          value.includes('.pk') ||
                          value === selectedDomain
                        ) {
                          setEmailError(true);
                          setFromEmail('');
                        } else {
                          setEmailError(false);
                          setFromEmail(value);
                        }
                      }}
                    />
                    {selectedDomain ? (
                      <Typography className="">
                        <strong>@{selectedDomain}</strong>
                      </Typography>
                    ) : (
                      '@example.com'
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextField
                    fullWidth
                    required
                    type="email"
                    id="outlined-required"
                    className="mb-3"
                    label="Email To:"
                    value={toEmail}
                    onChange={(e) => setToEmail(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextField
                    fullWidth
                    required
                    type="text"
                    id="outlined-required"
                    className="mb-3"
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12 col-sm-12">
                  <TextField
                    fullWidth
                    required
                    id="outlined-multiline-static"
                    className="mb-3"
                    label="Body"
                    multiline
                    rows={4}
                    placeholder="Enter your mesage here..."
                    value={bodyMessage}
                    onChange={(e) => setBodyMessage(e.target.value)}
                  />
                </div>
                <div className="col-lg-12 mt-3">
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    href="#file-upload"
                    onChange={(e) => setAttachmentData(e.target.files[0])}
                  >
                    Upload a file
                    <VisuallyHiddenInput type="file" />
                  </Button>
                  <Typography variant="p" fontSize="medium" className="ms-2">
                    {attachmentData && attachmentData.name}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <LoadingButton
                loading={isLoadingBtn}
                loadingPosition="start"
                startIcon={<ForwardToInboxIcon />}
                variant="contained"
                type="submit"
                size="small"
              >
                Send
              </LoadingButton>
            </div>
          </form>
        </div>
        {/* </Scrollbar> */}
        {/* </Card> */}
      </Container>
    </>
  );
}
