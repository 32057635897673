/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import SettingsIcon from '@mui/icons-material/Settings';
import { Close, Delete } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link, useNavigate } from 'react-router-dom';
// apis
import { useSnackbar } from 'notistack';
import { _addDomain, _deleteDomain, _domainVerification, _getDomainList } from '../../DAL/domain';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import { useAppContext } from '../../hooks/AppContext';
import UpgradeDialog from '../../components/upgrade-dialog/UpgradeDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'domainId', label: '#', alignRight: false, center: true },
  { id: 'domainName', label: 'Domains', alignRight: false },
  { id: 'domainDetail', label: 'Domain Insights', alignRight: false, center: true },
  { id: 'status', label: 'Verification Status', alignRight: false, center: true },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  padding: '2%',
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log(array, 'array-----');
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function DomainPage(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { customerDetail, setCustomerDetail } = useAppContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState(false);

  const [domainList, setDomainList] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [rowId, setRowId] = useState();
  const [name, setName] = useState('');
  const [verificationStatus, setVerificationStatus] = useState();
  const [record, setRecord] = useState({
    mx: false,
    dmarc_txt: false,
    spf_txt: false,
  });
  const [openDialog, setOpenDialog] = useState(null);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [windowWidth, setWindowWidth] = useState('lg');

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenUpgradeDialog(false);
  };

  const handleOpenUpgradeDialog = () => {
    setOpenUpgradeDialog(true);
  };

  const handleNavigate = () => {
    navigate('/plans');
  };

  // api

  const handleAddDomain = async (e) => {
    e.preventDefault();
    const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    if (!domainRegex.test(newDomain)) {
      setIsLoadingBtn(false);
      enqueueSnackbar('Please enter a valid domain name', { variant: 'error' });
      return;
    }
    setIsLoadingBtn(true);
    const response = await _addDomain({ name: newDomain });
    if (response.code === 200) {
      setIsLoadingBtn(false);
      handleCloseModal();
      setDomainList((prev) => [...prev, response.data]);
      setCustomerDetail({
        ...customerDetail,
        total_domains: customerDetail.total_domains + 1,
      });
      // getDomainList()
      enqueueSnackbar(response.message, { variant: 'success' });
      setNewDomain('');
    } else {
      setIsLoadingBtn(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(`Error: ${response.code}`);
      setNewDomain('');
    }
  };

  const getDomainList = async () => {
    const postData = {
      name: '',
      verification_status: '',
    };
    setIsLoading(true);
    const response = await _getDomainList(page, rowsPerPage, postData);
    console.log(page, 'domainpaggess');
    console.log(rowsPerPage, 'rowsperpagesss');
    if (response.code === 200) {
      setDomainList(response.data.domains);
      setTotalCount(response.data.count ? response.data.count : 0);
      console.log(response.data.count, 'countsssss');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(response.message, 'error domain listing');
    }
  };

  const handleDeleteDomain = async (e) => {
    e.preventDefault();
    setIsLoadingDeleteBtn(true);
    console.log('delete', rowId);
    const response = await _deleteDomain(rowId);
    if (response.code === 200) {
      const deleteRow = domainList.filter((val) => val._id != rowId);
      setDomainList(deleteRow);
      setCustomerDetail({
        ...customerDetail,
        total_domains: customerDetail.total_domains - 1,
      });
      setIsLoadingDeleteBtn(false);
      setOpenDialog(false);
      handleCloseMenu();
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      setIsLoadingDeleteBtn(false);
      handleCloseMenu();
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(response.message, 'delete domain error');
    }
  };

  const verifyDomain = async (e, name) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await _domainVerification({ name });
    if (response.code == 200) {
      setIsLoading(false);
      setVerificationStatus(response.data.verification_status);
      setRecord({
        mx: response.data.MX,
        dmarc_txt: response.data.DMARC_TXT,
        spf_txt: response.data.SPF_TXT,
      });
      enqueueSnackbar(response.message, { variant: 'success' });
      setIsLoading(true);
      const result = await _getDomainList();
      if (result.code === 200) {
        setDomainList(result.data.domains);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: 'error' });
        console.log(result.message, 'domain list error');
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(response.message, 'domain verification error');
    }
  };

  const handleSaveRow = (row) => {
    if (row) {
      localStorage.setItem('selectedDomain', JSON.stringify(row));
    }
  };

  // ----------------

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenVerificationModal = async (e, name) => {
    setOpenVerificationModal(true);
    setName(name);
    verifyDomain(e, name);
  };

  const handleCloseVerificationModal = () => {
    setOpenVerificationModal(false);
    setVerificationStatus(true);
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
    console.log('handle', id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = domainList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - domainList?.length) : 0;
  // const filteredUsers = applySortFilter(domainList, getComparator(order, orderBy), filterName);
  // const isNotFound = !filteredUsers?.length && !!filterName;

  useEffect(() => {
    getDomainList();
  }, [page, rowsPerPage]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  console.log(customerDetail, 'hfdghfdghfdhg');
  return (
    <>
      <Helmet>
        <title> Domains </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Domains
          </Typography>
          {customerDetail?.total_domains < customerDetail?.plan.total_domains_count ? (
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleOpenModal()}>
              Add Domain
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<Iconify icon="ph:star-fill" color="#eba100" />}
              onClick={handleOpenUpgradeDialog}
            >
              Add Domain
            </Button>
          )}
        </Stack>
        <UpgradeDialog
          handleCloseDialog={handleCloseDialog}
          openUpgradeDialog={openUpgradeDialog}
          isLoadingBtn={isLoadingBtn}
          handleNavigate={handleNavigate}
          upgradeHeading="It's time to upgrade."
          upgradeParagraph="Your have reached the limit of your current plan. To add more domains, you need to choose a higher plan."
          upgradeIcon="lucide:smile"
        />
        <Card>
          {/* <UserListToolbar numSelected={selected?.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table className="domain-table">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={domainList?.length}
                    numSelected={selected.length}
                    // onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {domainList?.map((row, index) => {
                      const { _id, name, verification_status } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell align="center">{rowsPerPage * page + (index + 1)}</TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="center">
                            <Link to={`${_id}`} state={{ domainDetail: row }}>
                              <Button
                                className="btn-detail"
                                size="small"
                                style={{ letterSpacing: '0.5px', padding: '2px 15px' }}
                                variant="outlined"
                              >
                                {/* <VerifiedUserIcon className="icon-spin me-2" /> */}
                                <VerifiedUserIcon className="me-2" />
                                Verify your Domain
                              </Button>
                            </Link>
                          </TableCell>

                          <TableCell align="center">
                            {verification_status === false ? (
                              <Label
                                style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                color={(verification_status === false && 'warning') || 'success'}
                              >
                                Pending
                              </Label>
                            ) : (
                              <Label
                                style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                color={(verification_status === false && 'error') || 'success'}
                              >
                                Verified
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                setCurrentRow(row);
                                handleOpenMenu(e, _id);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>

                  {domainList?.length == 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph isLoading="true">
                              No Data Found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            className="domain-table-pagination"
            rowsPerPageOptions={[5, 50, 100, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }} onClick={(e) => handleClickOpenDialog(e)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* dialog: add new domain */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="large"
      >
        <div className="d-flex flex-column " style={{ padding: '6%' }}>
          <div
            style={{ paddingBottom: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h6">Add a new domain</Typography>
            <IconButton onClick={handleCloseModal} style={{ color: 'grey' }}>
              <Close />
            </IconButton>
          </div>
          <div>
            <form className="form-group" onSubmit={handleAddDomain}>
              <TextField
                fullWidth
                required
                className="mb-3"
                id="outlined-required"
                label="New Domain"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
              />
              <div className="d-flex justify-content-end" style={{ paddingTop: '20px' }}>
                <Button
                  className="close-btn me-3"
                  startIcon={<Close />}
                  variant="outlined"
                  onClick={() => handleCloseModal()}
                  size="small"
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isLoadingBtn}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
          {'Do you want to delete Domain?'}
        </DialogTitle>
        <DialogContent>
          <Typography style={{ marginLeft: '10px' }}>
            <b>Domain:</b> {currentRow?.name}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '0 32px 32px', marginTop: '3%' }}>
          <Button
            className="close-btn"
            startIcon={<Close />}
            variant="outlined"
            onClick={handleCloseDialog}
            size="small"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoadingDeleteBtn}
            loadingPosition="start"
            startIcon={<Delete />}
            variant="contained"
            size="small"
            onClick={(e) => handleDeleteDomain(e)}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
