import { invokeApi } from '../utils/invokeApi';

export const _activePlans = async () => {
  const requestObj = {
    path: `api/plan/get_active_plans`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    //   postData: data,
  };
  return invokeApi(requestObj);
};

export const _createSourceTokent = async (data) => {
  const requestObj = {
    path: `api/subscription/create_source_token`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _addSubscription = async (data) => {
  const requestObj = {
    path: `api/subscription/add_subscription`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _confirmIncSubscription = async (data) => {
  const requestObj = {
    path: `api/subscription/confirm_incomplete_subscription`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _changeCard = (data) => {
  const requestObj = {
    path: 'api/card/add_customer_card',
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
