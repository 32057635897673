/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  CircularProgress,
  // styled,
  // AvatarGroup,
  // Avatar,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateIcon from '@mui/icons-material/Create';
import { Cancel, Close, Delete } from '@mui/icons-material';
// apis
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
// import { s3baseUrl } from '../../config/config';
import { _fileUpload } from '../../DAL/fileUpload';
import {
  _addSupportTicket,
  _closeSupportTicket,
  _deleteSupportTicket,
  _listSupportTicket,
} from '../../DAL/supportTicket';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'domainId', label: '#', alignRight: false, center: true },
  // { id: 'image', label: 'Image', alignRight: false, center: true },
  { id: 'subject', label: 'Subject', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'comment_badge_count', label: 'Message', alignRight: false },
  { id: 'ticketStatus', label: 'Ticket Status', alignRight: false, center: true },
  { id: 'last_action_date', label: 'Last Activity', alignRight: false, center: true },
  { id: 'date', label: 'CreatedAt', alignRight: false, center: true },
  { id: 'time', label: 'UpdatedAt', alignRight: false, center: true },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  padding: '30px',
};

// for img uploading
// const VisuallyHiddenInput = styled('input')`
//   clip: rect(0 0 0 0);
//   clip-path: inset(50%);
//   height: 1px;
//   overflow: hidden;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   white-space: nowrap;
//   width: 1px;
// `;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

const SupportTicket = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoadingAddBtn, setIsLoadingAddBtn] = useState(false);

  const [supportTicket, setSupportTicket] = useState([]);
  const [subject, setSubject] = useState('');
  const [image, setImage] = useState();
  const [description, setDescription] = useState('');
  const [rowId, setRowId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [windowWidth, setWindowWidth] = useState('lg');
  const [openCloseTicketDialog, setOpenCloseTicketDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  const handleClickOpenCloseTicketDialog = () => {
    setOpenCloseTicketDialog(true);
    setOpen(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenCloseTicketDialog(false);
  };

  // api

  const getListOfSupportTickets = async () => {
    setIsLoading(true);
    const reqObj = {
      status: 'all',
    };
    const response = await _listSupportTicket(page, rowsPerPage);
    if (response.code === 200) {
      setIsLoading(false);
      console.log(response, 'response of list ST');
      setSupportTicket(response.support_ticket);
      setTotalCount(response.user_support_ticket_count);
    } else {
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const handleAddSupportTicket = async (e) => {
    e.preventDefault();
    if (description === '') {
      enqueueSnackbar('description is not allowed to be empty', { variant: 'error' });
      return;
    }
    setIsLoadingAddBtn(true);
    // if (!image) {
    const reqObj = {
      subject,
      description,
    };
    const response = await _addSupportTicket(reqObj);
    if (response.code === 200) {
      console.log(response, 'response');
      setSupportTicket((prev) => [response.support_ticket, ...prev]);
      enqueueSnackbar(response.message, { variant: 'success' });
      setIsLoadingAddBtn(false);
      handleCloseModal();
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoadingAddBtn(false);
    }
    // }
    // const reqObj = {
    //   file: image,
    //   directory: 'email-attachment',
    // };
    // const uploadResponse = await _fileUpload(reqObj);
    // if (uploadResponse.code === 200) {
    //   const reqObj = {
    //     subject,
    //     image: uploadResponse.path,
    //     description,
    //   };
    //   const response = await _addSupportTicket(reqObj);
    //   if (response.code === 200) {
    //     console.log(response, 'response');
    //     setSupportTicket((prev) => [...prev, response.support_ticket]);
    //     enqueueSnackbar(response.message, { variant: 'success' });
    //     setIsLoading(false);
    //     handleCloseModal();
    //   } else {
    //     console.log(`Error: ${response.code}`);
    //     enqueueSnackbar(response.message, { variant: 'error' });
    //     setIsLoading(false);
    //   }
    // } else {
    //   setIsLoading(false);
    //   console.log(`Error: ${uploadResponse.code}`);
    //   enqueueSnackbar(uploadResponse.code, { variant: 'error' });
    // }
  };

  const handleDeleteSupportTicket = async (e, id) => {
    e.preventDefault();
    setIsLoadingBtn(true);
    const response = await _deleteSupportTicket(id);
    if (response.code === 200) {
      setIsLoadingBtn(false);
      const updatedList = supportTicket.filter((val) => val._id != id);
      setSupportTicket(updatedList);
      enqueueSnackbar(response.message, { variant: 'success' });
      handleCloseDialog();
    } else {
      setIsLoadingBtn(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const handleCloseSupportTicket = async (e, id) => {
    e.preventDefault();
    setIsLoadingBtn(true);
    const response = await _closeSupportTicket(id);
    if (response.code === 200) {
      setIsLoadingBtn(false);
      getListOfSupportTickets();
      // const updatedList = supportTicket.filter((val) => val._id != id);
      // setSupportTicket(updatedList);
      enqueueSnackbar(response.message, { variant: 'success' });
      handleCloseDialog();
      setIsLoading(false);
    } else {
      setIsLoadingBtn(false);
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  // function for add ticket modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSubject('');
    // setImage()
    setDescription('');
    setOpenModal(false);
  };
  // ------------------------------------

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - supportTicket?.length) : 0;
  // const filteredUsers = applySortFilter(supportTicket, getComparator(order, orderBy), filterName);
  // const isNotFound = !filteredUsers?.length && !!filterName;

  const formateDate = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);

    const formattedDate = isoDate.toLocaleDateString();

    return formattedDate;
  };
  const formateTime = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedTime = isoDate.toLocaleTimeString();

    return formattedTime;
  };

  useEffect(() => {
    getListOfSupportTickets();
  }, [page, rowsPerPage]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Support Ticket </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Support Ticket
          </Typography>
          <Button
            variant="contained"
            style={{ padding: '5px' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleOpenModal()}
          >
            Create Support Ticket
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected?.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table className="domain-table">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={supportTicket?.length}
                    numSelected={selected.length}
                    // onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {/* {supportTicket && supportTicket?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                       const { _id, subject, description,image,status,response_status, ticket_status, createdAt } = row;
                      const selectedUser = selected.indexOf(subject) !== -1; */}

                    {/* updated */}
                    {supportTicket &&
                      supportTicket?.map((row, index) => {
                        const {
                          _id,
                          subject,
                          description,
                          response_status,
                          ticket_status,
                          createdAt,
                          updatedAt,
                          comment_badge_count,
                          last_action_date,
                        } = row;
                        const selectedUser = selected.indexOf(subject) !== -1;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell align="center">{rowsPerPage * page + (index + 1)}</TableCell>

                            {/* <TableCell align="center">
                            <AvatarGroup max={4} className='flex-row justify-content-center'>
                              <Avatar alt="st-images" src={s3baseUrl + image} />
                            </AvatarGroup>
                          </TableCell> */}

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px',
                              }}
                            >
                              <Typography
                                className="subject-style"
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/supportticket-detail/${_id}`)}
                              >
                                {subject}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 150 }}
                            >
                              <Tooltip title={description}>
                                <Typography noWrap>{description}</Typography>
                              </Tooltip>
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Label style={{ letterSpacing: '0.5px', padding: '15px 15px' }} color="warning">
                                {comment_badge_count}
                              </Label>
                            </TableCell>

                            <TableCell align="center">
                              {
                                <Label
                                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                  color={(response_status === 1 && ticket_status === 1 && 'error') || 'success'}
                                >
                                  {' '}
                                  {response_status === 1 && ticket_status === 1 ? 'Closed' : 'Open'}
                                </Label>
                              }
                            </TableCell>

                            <TableCell align="center" sx={{ maxWidth: 300, minWidth: 170 }}>
                              {moment(last_action_date).fromNow()}
                            </TableCell>

                            <TableCell align="center">{moment(createdAt).format('lll')}</TableCell>

                            <TableCell align="center">{moment(updatedAt).format('lll')}</TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => {
                                  setCurrentRow(row);
                                  handleOpenMenu(e, _id);
                                }}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>

                  {supportTicket.length == 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No Data Found
                            </Typography>

                            {/* <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography> */}
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            className="domain-table-pagination"
            rowsPerPageOptions={[5, 50, 100, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Link to={`/supportticket-detail/${rowId}`}>
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Detail
          </MenuItem>
        </Link>
        {currentRow?.ticket_status === 0 && (
          <MenuItem onClick={() => handleClickOpenCloseTicketDialog()}>
            <Iconify icon={'mdi:close'} sx={{ mr: 2 }} />
            Close
          </MenuItem>
        )}
        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleClickOpenDialog()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* modal: add new ticket */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="large"
      >
        <div className="d-flex flex-column " style={{ padding: '5%' }}>
          <div
            style={{ paddingBottom: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h6">Add a new domain</Typography>
            <IconButton onClick={handleCloseModal} style={{ color: 'grey' }}>
              <Close />
            </IconButton>
          </div>
          <div>
            <TextField
              fullWidth
              required
              type="text"
              className="mb-3"
              id="outlined-required"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              required
              fullWidth
              id="outlined-multiline-static"
              className="mb-3"
              label="Description"
              multiline
              rows={4}
              placeholder="Enter your mesage here..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {/* <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              href="#file-upload"
              onChange={(e) => setImage(e.target.files[0])}
            >
              Upload image
              <VisuallyHiddenInput type="file" />
            </Button>
            <Typography variant="p" fontSize="medium" className="ms-2">
              {image && image.name}
            </Typography> */}
            <div className="d-flex justify-content-end">
              <Button
                component="label"
                variant="outlined"
                className="me-3 close-btn"
                startIcon={<Close />}
                onClick={() => handleCloseModal()}
                size="small"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoadingAddBtn}
                loadingPosition="start"
                startIcon={<CreateIcon />}
                variant="contained"
                onClick={(e) => handleAddSupportTicket(e)}
                size="small"
              >
                Create Ticket
              </LoadingButton>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Close dialog */}
      <div>
        <Dialog
          open={openCloseTicketDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to Close Support Ticket?'}
          </DialogTitle>
          <Typography style={{ marginLeft: '31px' }}>
            <b>Subject:</b> {currentRow?.subject}
          </Typography>
          <DialogActions style={{ padding: '0 32px 32px', marginTop: '5%' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              loadingPosition="start"
              startIcon={<Close />}
              variant="contained"
              size="small"
              onClick={(e) => handleCloseSupportTicket(e, rowId)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
      {/* delete dialog */}
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to delete Support Ticket?'}
          </DialogTitle>
          <Typography style={{ marginLeft: '31px' }}>
            <b>Subject:</b> {currentRow?.subject}
          </Typography>
          <DialogActions style={{ padding: '0 32px 32px', marginTop: '5%' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              loadingPosition="start"
              startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={(e) => handleDeleteSupportTicket(e, rowId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default SupportTicket;
