/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, Card, CircularProgress, Container, Divider, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CreditCardInput from 'react-credit-card-input';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { _detailCustomer } from '../../DAL/customer';
import { activePlans, addSubscription, _confirmIncSubscription, _addSubscription, _activePlans } from '../../DAL/plans';
import { useAppContext } from '../../hooks/AppContext';

export default function MakePayment() {
  const { customerDetail, setCustomerDetail } = useAppContext();

  const stripe = useStripe();
  const elements = useElements();
  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
  };
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [plans, setPlans] = useState();
  const [planData, setPlanData] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [resPostData, setResPostData] = useState('');

  function toCamelCase(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }

  const getCustomerDetail = async () => {
    const response = await _detailCustomer();
    if (response.code === 200) {
      setCustomerDetail(response.customer);
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getCustomerDetail();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (elements == null) {
      return;
    }
    setIsLoadingBtn(true);
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement, stripe, 'elements');
    stripe
      .createToken(cardElement)
      .then((payload) => {
        console.log('[token]', payload);
        if (payload.error) {
          enqueueSnackbar(payload.error.message, { variant: 'error' });
        }
        console.log(payload, 'stripe token');
        planPayment(payload.token.id, cardElement);
        // setIsLoadingBtn(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingBtn(false);
      });
  };

  const planPayment = async (token, cardElement) => {
    const reqObj = {
      plan_id: planData._id,
      source_token: token,
    };
    console.log(reqObj, 'postdatatata');
    const response = await _addSubscription(reqObj);
    console.log(response, 'clienttsecretekey');
    if (response.code === 200) {
      // navigate("/plans")
      setCustomerDetail({
        ...customerDetail,
        plan_id: planData._id,
      });
      getCustomerDetail();
      setIsLoadingBtn(false);
      enqueueSnackbar(response.message, { variant: 'success' });
      navigate(`/plans`);
    } else if (response.code === 210) {
      handleSecureCard(response.client_secret, cardElement, response.subscription_id);
    } else {
      setIsLoadingBtn(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(`Error add subscription api: ${response.message}`);
    }
  };

  const confirmPayment = async (id) => {
    const postData = {
      subscription_id: id,
    };
    console.log(postData, 'pooossttdatataa');
    const resp = await _confirmIncSubscription(postData);
    if (resp.code === 200) {
      setCustomerDetail({
        ...customerDetail,
        plan_id: planData._id,
        plan: planData,
      });
      setIsLoading(false);
      enqueueSnackbar(resp.message, { variant: 'success' });
      navigate(`/plans`);
      // getCustomerDetail();
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' });
      console.log(resp.message, 'confrim payment message');
      setIsLoading(false);
    }
    setIsLoadingBtn(false);
  };

  const handleSecureCard = (clientSecret, cardElement, id) => {
    console.log(id, 'id---');
    enqueueSnackbar('Processing card...', { variant: 'info' });
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then((result) => {
        setIsLoading(false);
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: 'error' });
          console.log(result.error.message, '3d paymnent failed');
        }
        confirmPayment(id);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const getActivePlans = async () => {
    setIsLoading(true);
    const response = await _activePlans();
    console.log(response, 'responseactiveplan');
    if (response.code === 200) {
      setIsLoading(false);
      setPlans(response.plans);
      const data = 'data';
      const filteredPlan = response.plans
        .filter((val) => val._id === id)
        .reduce((acc, val) => {
          acc[data] = val;
          return acc;
        }, {});
      setSelectedAlert(filteredPlan.data.name);
      console.log(filteredPlan.data, 'fill--------');
      setPlanData(filteredPlan.data);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(`Error: ${response.code}`);
    }
  };

  const handleSelectedPlan = (planName) => {
    setSelectedAlert(planName);
    const selectedPlan = plans.find((val) => val.name === planName);
    setPlanData(selectedPlan);
  };

  const isPlanSelected = (plan) => {
    console.log(plan, selectedAlert, 'aaaaa');
    return plan === selectedAlert;
  };

  useEffect(() => {
    getActivePlans();
  }, []);
  // console.log(plans ,'plaaaaaaaaaaaaaannnnnnnssssss');

  return (
    <>
      <Helmet>
        <title> Payment Plans </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div className="d-flex align-items-center">
            <KeyboardReturnIcon
              style={{
                marginRight: '20px',
                background: '#89c7aa',
                padding: '5px',
                borderRadius: '30px',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(-1)}
            />
            <Typography variant="h4" gutterBottom>
              Payment Plans
            </Typography>
          </div>
        </Stack>

        <Card className="p-4">
          {isLoading ? (
            <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div className="text-center">
                <h5>Upgrade your site with the {planData?.name} Plan</h5>
                <p>Empower your brand, drive collaboration and launch compaigns at scale.</p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="payment-card p-3"
                    style={{ background: '#f7f7f7', border: '1px solid #ccc', borderRadius: '7px' }}
                  >
                    <p className="fw-bold">
                      Pay for the subscription to confirm it. After payment you will be given access to the selected
                      plan then you can make your sites and empower your business. Pleasse confirm your subscription
                      plan.
                    </p>
                    <ul>
                      <li>
                        <p>Subscription Ammount</p>
                        <p>{`$${planData?.amount}`}</p>
                      </li>
                      <li>
                        <p>Subscription Plan</p>
                        <p>{toCamelCase(planData?.name)}</p>
                      </li>
                      <li>
                        <p>Subscription Duration</p>
                        <p>Monthly</p>
                      </li>
                    </ul>
                    <Divider className="mb-3" color="primary" />
                    <ul>
                      <li>
                        <p>Sub Total</p>
                        <p>{`$${planData?.amount}`}</p>
                      </li>
                      <li>
                        <p>VAT</p>
                        <p>$0</p>
                      </li>
                    </ul>
                    <Divider className="mb-3" color="primary" />
                    <form onSubmit={handleSubmit}>
                      <div style={{ position: 'relative', height: '85px' }}>
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                      </div>
                      <LoadingButton
                        variant="contained"
                        loading={isLoadingBtn}
                        loadingPosition="start"
                        className="mb-3"
                        // onClick={(e) => handleSubmit(e)}
                        type="submit"
                        fullWidth
                        disabled={!stripe}
                      >
                        Pay Now
                      </LoadingButton>
                    </form>
                    <p className="mb-0">
                      <TaskAltIcon className="me-2" color="primary" style={{ fontSize: '16px' }} />
                      30-day money-back guarantee
                    </p>
                    <p className="mb-0">
                      <LockIcon className="me-2" color="primary" style={{ fontSize: '16px' }} />
                      Encrypted and secure payments
                    </p>
                    <p className="mt-3">
                      By continuing you agree with our{' '}
                      <a href="#" className="fw-bold">
                        Terms of Service
                      </a>
                      . We will process your personal data for the fulfillment of your order and other purposes as per
                      our{' '}
                      <a href="#" className="fw-bold">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="selected-plan">
                    <h6 className="mb-3">Subscription Plan</h6>
                    <Stack className="mb-4" sx={{ width: '100%' }} spacing={1}>
                      {plans &&
                        plans.map((plan, key) => (
                          <Alert
                            key={key}
                            icon={false}
                            sx={{ position: 'relative' }}
                            severity="info"
                            onClick={() => {
                              if (customerDetail?.plan_id !== plan._id) {
                                handleSelectedPlan(plan.name);
                              }
                              //  else {
                              //   enqueueSnackbar("This plan is already activated",{variant:"error"})
                              // }
                            }}
                            style={{
                              border: isPlanSelected(plan.name) ? '1px solid' : 'none',
                              borderLeft: isPlanSelected(plan.name) ? '5px solid' : 'none',
                              cursor: 'pointer',
                            }}
                            className="price-alert selected"
                          >
                            {customerDetail?.plan_id === plan._id ? (
                              <div className="ribbon-2" style={{ top: '4px !important' }}>
                                <p className="mb-0">Activated</p>
                              </div>
                            ) : (
                              ''
                            )}
                            <p className="mb-0">{plan.name}</p>
                            <p
                              className="mb-0"
                              style={{ marginRight: customerDetail?.plan_id === plan._id ? '70px' : '' }}
                            >
                              ${plan.amount}.00
                            </p>
                          </Alert>
                        ))}
                      {/* <Alert
                        icon={false}
                        severity="info"
                        onClick={() => handleSelectedPlan('Basic')}
                        style={{
                          border: isPlanSelected('Basic') ? '1px solid' : 'none',
                          borderLeft: isPlanSelected('Basic') ? '5px solid' : 'none',
                          cursor: 'pointer',
                        }}
                        className="price-alert selected"
                      >
                        <p className="mb-0">Free</p>
                        <p className="mb-0">$0.00</p>
                      </Alert>
                      <Alert
                        icon={false}
                        style={{
                          border: isPlanSelected('Premium') ? '1px solid' : 'none',
                          borderLeft: isPlanSelected('Premium') ? '5px solid' : 'none',
                          cursor: 'pointer',
                        }}
                        severity="info"
                        onClick={() => handleSelectedPlan('Premium')}
                        className="price-alert"
                      >
                         <p className="mb-0">Premium</p>
                        <p className="mb-0">$19.95</p> 
                        <p className="mb-0">{planData?.name}</p>
                        <p className="mb-0">{`$${planData?.amount}`}</p>
                      </Alert>
                      <Alert
                        icon={false}
                        severity="info"
                        style={{
                          border: isPlanSelected('Enterprise') ? '1px solid' : 'none',
                          borderLeft: isPlanSelected('Enterprise') ? '5px solid' : 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSelectedPlan('Enterprise')}
                        className="price-alert"
                      >
                         <p className="mb-0">$39</p> 
                        <p className="mb-0">Enterprize</p> 
                        <p className="mb-0">{planData?.name}</p>
                        <p className="mb-0">{`$${planData?.amount}`}</p>
                      </Alert> */}
                    </Stack>
                    <div>
                      <h6 className="mb-2">Selected Plan Information</h6>
                      <div className="plan-information border">
                        <div
                          className="d-flex justify-content-between align-items-center px-3 py-1"
                          style={{ background: '#00a95a' }}
                        >
                          <p className="mb-0">{planData?.name}</p>
                          <p className="mb-0">{`$${planData?.amount}`}</p>
                        </div>
                        {planData && planData?.name === 'Basic' ? (
                          <ul className="list-unstyled p-3">
                            {planData?.features?.map((feature, key) => (
                              <li className="d-flex" key={key}>
                                <DoneIcon className="list-icon me-2" color="primary" />
                                {feature.feature}
                              </li>
                            ))}
                          </ul>
                        ) : planData?.name === 'Premimum' ? (
                          <ul className="list-unstyled p-3">
                            {planData?.features?.map((feature, key) => (
                              <li className="d-flex" key={key}>
                                <DoneIcon className="list-icon me-2" color="primary" />
                                {feature.feature}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul className="list-unstyled p-3">
                            {planData?.features?.map((feature, key) => (
                              <li className="d-flex" key={key}>
                                <DoneIcon className="list-icon me-2" color="primary" />
                                {feature.feature}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
