/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { _codeVerification } from '../../DAL/auth';
import logo from "../../assets/images/logo.png";


const CodeVerification = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [code, setCode] = useState("")
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true)
    const userEmail = localStorage.getItem("user-email")
    // const reqObj = {
    //     email: localStorage.getItem("user-email"),
    //     verification_code: code
    // }
    const response = await _codeVerification({ email: userEmail, verification_code: code })
    if (response.code === 200) {
      setIsLoadingBtn(false)
      navigate("/reset-password")
      enqueueSnackbar(response.message, { variant: "success" })
    }
    else {
      setIsLoadingBtn(false)
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: "error" })
    }
  }
  return (
    // 2
    <div className="container-fluid">
      <div className="row" style={{ height: "100vh" }}>
        <div className="col- col-lg-12 col-xl-8 py-4 d-flex flex-column">
          <img src={logo} alt="logo" className="d-block ps-5" width={200} />
          <div
            className="d-flex flex-column align-items-center"
            style={{ padding: "60px 0" }}
          >
            <div className="text-left custom-width w-50 mb-3">
              <a href="/login"><i className="fa-solid fa-chevron-left fa-2xs" /> Go back</a>
              <h1 className="mb-3">Reset password</h1>
              <Alert className='success-alert mb-3' variant="filled" severity="success">We have emailed you verification code.</Alert>
              <form className="form-label" onSubmit={handleCodeVerification}>
                <div className="form-group mb-4">
                  <label className='mb-1 font-weight-bold' htmlFor="exampleInputEmail1">Verification code</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter your verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                  />

                </div>

                {/* <button type="submit"
                                    style={{ backgroundColor: "#0c2e60", borderColor: "#0c2e60", padding: ".75rem 1.5rem" }}
                                    className="btn btn-lg btn-primary w-100"
                                >
                                    Submit
                    </button> */}
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoadingBtn}
                  loadingPosition="start"
                  startIcon=" "
                  variant="contained"
                  style={{ padding: ".75rem 1.5rem" }}
                >
                  Submit
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-4 right-col" />
      </div>
    </div>
  )
}

export default CodeVerification