import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DomainPage from './pages/domain/DomainPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import AuthLayout from './layouts/AuthLayout';
import Signin from './pages/authentication/Signin';
import Signup from './pages/authentication/Signup';
import ForgotPassword from './pages/authentication/ForgotPassword';
import CodeVerification from './pages/authentication/CodeVerification';
import ResetPassword from './pages/authentication/ResetPassword';
import Profile from './pages/userprofile/Profile';
import DomainDetailPage from './pages/domain/DomainDetailPage';
import SendEmail from './pages/sendemail/SendEmail';
import SupportTicket from './pages/supportticket/SupportTicket';
import SupportTicketDetail from './pages/supportticket/SupportTicketDetail';
import Settings from './pages/settings/Settings';
import EmailPlans from './pages/plans/EmailPlans';
import MakePaymentWrapper from './pages/plans/MakePaymentWrapper';
import Transactions from './pages/transactions/Transactions';
import Security from './pages/profile/components/Security';
import SubscriptionCard from './pages/profile/components/SubscriptionCard';
import ManageCardWrapper from './pages/profile/components/ManageCardWrapper';
import Accounts from './pages/profile/components/Accounts';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'userprofile', element: <Profile /> },
        { path: 'domain', element: <DomainPage /> },
        { path: 'domain/:id', element: <DomainDetailPage /> },
        // { path: 'emails', element: <EmailsPage /> },
        { path: 'send-email', element: <SendEmail /> },
        { path: 'support-ticket', element: <SupportTicket /> },
        { path: 'supportticket-detail/:id', element: <SupportTicketDetail /> },
        { path: 'settings', element: <Settings /> },
        { path: 'plans', element: <EmailPlans /> },
        { path: 'payment-plans/:id', element: <MakePaymentWrapper /> },
        { path: 'transactions', element: <Transactions /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'security', element: <Security /> },
        { path: 'manage-card', element: <ManageCardWrapper /> },
        { path: 'subscription', element: <SubscriptionCard /> },
      ],
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: 'login', element: <Signin /> },
        { path: 'signup', element: <Signup /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'verify-code', element: <CodeVerification /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/dashboard" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
