import { invokeApi } from '../utils/invokeApi';

export const _getTransactions = async (page, limit) => {
  const requestObj = {
    path: `api/subscription/list_user_transactions?page=${page}&limit=${limit}`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _cancelSubscription = async (id) => {
  console.log(id, 'trfthrfhtrh');
  const requestObj = {
    path: `api/subscription/cancel_subscription/${id}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};
