/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = '', title, icon = '', ...other }) {
  return (
    <Typography
      variant="h5"
      align="center"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {icon && (
        <div
          style={{
            fontSize: '60px',
            background: '#02337310',
            margin: '10px',
            padding: '10px',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
          }}
        >
          <Icon
            icon={icon}
            style={{
              verticalAlign: 'text-top',
            }}
          />
        </div>
      )}
      {title}
    </Typography>
  );
}
