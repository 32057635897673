/* eslint-disable no-unused-vars */
import { Avatar, Box, Button, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { s3baseUrl } from '../../config/config';
import { useAppContext } from '../../hooks/AppContext';
import { _editCustomer } from '../../DAL/customer';
import { _fileUpload } from '../../DAL/fileUpload';

const Profile = () => {
  const { customerDetail, setCustomerDetail } = useAppContext();
  console.log(customerDetail, 'customerDetailcustomerDetail');
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState(customerDetail?.first_name);
  const [lastName, setLastName] = useState(customerDetail?.last_name);
  const [email, setEmail] = useState(customerDetail?.user?.email);

  const [profileImage, setProfileImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadImage = async (e) => {
    console.log('imasdasdasd', e.target.files[0]);
    setProfileImage(e.target.files[0]);
  };

  const handleEditProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reqObj = {
      file: profileImage,
      directory: 'profileImage',
    };
    if (profileImage) {
      const response = await _fileUpload(reqObj);
      if (response.code === 200) {
        const reqObj = {
          first_name: firstName,
          last_name: lastName,
          profile_image: response.path,
          email,
        };
        const result = await _editCustomer(reqObj);
        if (result.code === 200) {
          setIsLoading(false);
          setCustomerDetail(result.data);
          enqueueSnackbar(result.message, { variant: 'success' });
        } else {
          enqueueSnackbar(result.message, { variant: 'error' });
          console.log(`Error Profile Edit: ${result.code}`);
        }
      } else {
        console.log(`Error Image Upload: ${response.code}`);
      }
    } else {
      const reqObj = {
        first_name: firstName,
        last_name: lastName,
        profile_image: customerDetail?.profile_image,
        email,
      };
      const result = await _editCustomer(reqObj);
      if (result.code === 200) {
        setIsLoading(false);
        setCustomerDetail(result.data);
        enqueueSnackbar(result.message, { variant: 'success' });
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        setIsLoading(false);
        console.log(`Error Profile Edit: ${result.code}`);
      }
    }
  };

  useEffect(() => {
    if (profileImage) {
      console.log(profileImage, 'profileImageprofileImage');
      setPreviewImage(URL.createObjectURL(profileImage));
    }
  }, [profileImage]);

  useEffect(() => {
    setFirstName(customerDetail?.first_name);
    setLastName(customerDetail?.last_name);
    setEmail(customerDetail?.user?.email);
    if (customerDetail?.profile_image) {
      setPreviewImage(s3baseUrl + customerDetail?.profile_image);
    }
  }, [customerDetail]);

  return (
    <>
      <div style={{ padding: '20px 40px' }}>
        <h1 className="mb-5">My Profile</h1>
        <div style={{ padding: '60px 40px', backgroundColor: '#fff', borderRadius: '7px' }}>
          <div>
            <h5>Profile Photo</h5>
            <p className="mt-1 mb-4">You can upload your own photo or use your profile picture from Gravatar.</p>
            <div className="d-flex align-items-center">
              <div>
                {previewImage ? (
                  <img src={previewImage} alt="profile-img" width="120" height="120" />
                ) : (
                  <Avatar
                    alt="profile image"
                    style={{
                      backgroundColor: '#0c2e60',
                      width: '120px',
                      height: '120px',
                      borderRadius: '0',
                    }}
                  />
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    marginLeft: '30px',
                    backgroundColor: '#e5e7eb',
                    color: '#000',
                    fontWeight: 'normal',
                    padding: '8px 22px',
                    boxShadow: '0 6px 8px 0 rgb(3 3 3 / 75%)',
                  }}
                >
                  <input hidden type="file" accept="image/*" onChange={(e) => handleUploadImage(e)} />
                  Upload photo
                </Button>
              </div>
            </div>
          </div>
          <Divider className="mt-5 mb-5" style={{ color: '#d6d6d6' }} />
          <div>
            <h5>Basic Information</h5>
            <div className="d-flex mt-5">
              <div className="form-group w-50 me-1 mb-3">
                <label className="mb-1" htmlFor="exampleInputFname1">
                  First Name
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="exampleInputFname1"
                    aria-describedby="fnameHelp"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </label>
              </div>
              <div className="form-group w-50 ms-1 mb-3">
                <label className="mb-1" htmlFor="exampleInputLname1">
                  Last Name
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="exampleInputLname1"
                    aria-describedby="lnameHelp"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div>
              <div className="form-group mb-3 me-1" style={{ width: '49.5%' }}>
                <label className="mb-1" htmlFor="exampleInputEmail1">
                  Email address
                  <input
                    type="email"
                    className="form-control mt-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <Divider className="mt-4 mb-5" style={{ color: '#d6d6d6' }} />
          {/* <div>
              <div>
                <h5>Login details</h5>
                <div className='d-flex align-items-center mt-5'>
                  <div className="form-group w-50 mb-3">
                    <label className="mb-1" htmlFor="exampleInputEmail1">
                      Email address
                      <input
                        type="email"
                        className="form-control mt-1"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={email}
                        disabled
                      // onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      className='ms-3'
                      style={{
                        backgroundColor: "#e5e7eb",
                        color: "#000",
                        fontWeight: "normal",
                        padding: "8px 22px",
                        boxShadow: "0 6px 8px 0 rgb(3 3 3 / 75%)"
                      }}
                      onClick={handleOpen}
                    >
                      Change email
                    </Button>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <div className="form-group w-100 mb-3">
                    <label className="mb-1" htmlFor="exampleInputPassword1">
                      Password
                      <input
                        type="password"
                        className="form-control mt-1"
                        id="exampleInputPassword1"
                        name="password"
                        value="12345"
                        disabled
                      />
                    </label>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      className='ms-3'
                      style={{
                        backgroundColor: "#e5e7eb",
                        color: "#000",
                        fontWeight: "normal",
                        padding: "8px 22px",
                        boxShadow: "0 6px 8px 0 rgb(3 3 3 / 75%)",
                        width: "max-content",
                      }}
                      onClick={handleOpenPasswordModal}
                    >
                      Change password
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <input type="checkbox" style={{ width: "18px", height: "18px", marginRight: "10px", borderColor: "#0c2e60" }} />Require to change password every 60 days
                </div>
              </div>
              <Divider className='mt-5 mb-5' style={{ color: "#d6d6d6" }} />
              <div>
                <h5>Two-factor authentication</h5>
                <p>Two-factor authentication is a security process where the user provides 2 forms of ID to log in to MailerLite. It helps to keep your account safe.</p>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    backgroundColor: "#e5e7eb",
                    color: "#000",
                    fontWeight: "normal",
                    padding: "8px 22px",
                    boxShadow: "0 6px 8px 0 rgb(3 3 3 / 75%)",
                    width: "max-content",
                  }}> Enable</Button>
              </div>
              <Divider className='mt-5 mb-5' style={{ color: "#d6d6d6" }} />
              <div>
                <h5>Language</h5>
                <select
                  className='mt-4'
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    height: "40px",
                    borderColor: "#ccc",
                  }}
                  name="languages" id="">
                  <option value="1">English</option>
                  <option value="2">Spanish</option>
                </select>
              </div>
              <Divider className='mt-5 mb-5' style={{ color: "#d6d6d6" }} />
              <div>
                <h5>Want to stay in touch?</h5>
                <p>Receive emailing tips and news once a week.</p>
                <div className="d-flex">
                  <input type="checkbox" style={{ width: "18px", height: "18px", marginTop: "5px", marginRight: "10px", borderColor: "#0c2e60" }} />I wish to subscribe to the MailerLite newsletter to receive emailing tips and news once a week. I know I can unsubscribe at any time.
                </div>
              </div>
              <Divider className='mt-5 mb-5' style={{ color: "#d6d6d6" }} />
              
            </div> */}
          <div className="d-flex justify-content-end">
            {/* <Button
              variant="contained"
              component="label"
              style={{
                fontWeight: "normal",
                padding: "8px 22px",
                boxShadow: "0 6px 8px 0 rgb(3 3 3 / 75%)",
                width: "max-content",
              }}
              onClick={(e) => handleEditProfile(e)}
            >
              Save Changes
            </Button> */}
            <LoadingButton
              loading={isLoading}
              loadingPosition="end"
              endIcon=" "
              variant="contained"
              style={{
                fontWeight: 'normal',
                padding: '8px 22px',
                boxShadow: '0 6px 8px 0 rgb(3 3 3 / 75%)',
                width: '170px',
              }}
              onClick={(e) => handleEditProfile(e)}
            >
              Save Changes
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
