/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import logomailcub from '../../../assets/images/logomailcub.png';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  // boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
  alignItems: 'flex-start',
  marginTop: '5px',
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const logo = (
    <Box sx={{ margin: '0px auto' }}>
      <img src={logomailcub} alt="" width="80" />
    </Box>
  );
  return (
    <>
      <StyledRoot sx={{ backgroundColor: 'white', boxShadow: 'none', display: { lg: 'none' } }}>
        <StyledToolbar style={{ justifyContent: 'space-between' }}>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <div className="d-flex">
              <Iconify width="30px" height="30px" icon="eva:menu-2-fill" />
              <div className="ms-2">{logo}</div>
            </div>
          </IconButton>

          {/* <Searchbar /> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {/* <LanguagePopover />
            <NotificationsPopover /> */}
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </>
  );
}
