import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MakePayment from './MakePayment';

const stripePromise = loadStripe(
  'pk_test_51NvF6bC8M7zQ783Y5tpYaYGfpfxOtbO4QKZLgH8Axd8zuQZ3xcYOCE12KMThYPJQjL4fNZneRl2SwAQnSIwEUbKw00cydY3Skk'
);

function MakePaymentWrapper() {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <MakePayment />
      </Elements>
    </div>
  );
}

export default MakePaymentWrapper;
