/* eslint-disable arrow-body-style */
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = () => {
  return [
    {
      title: 'dashboard',
      path: '/dashboard',
      icon: icon('ic_analytics'),
    },
    {
      title: 'Domains',
      path: '/domain',
      icon: icon('ic_user'),
    },
    {
      title: 'Send Email',
      path: '/send-email',
      icon: icon('ic_email'),
    },
    {
      title: 'transactions',
      path: '/transactions',
      icon: icon('ph_bank'),
    },
  ];
};

export default navConfig;
