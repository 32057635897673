/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CircularProgress,
  Container,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { Helmet } from 'react-helmet-async';
import { FileCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import { _detailCustomer } from '../../DAL/customer';
import { _generateApiKey } from '../../DAL/settings';
import Scrollbar from '../../components/scrollbar';
import { key } from '../../assets/index';

export default function Settings() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [windowWidth, setWindowWidth] = useState('lg');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCopyApiKey = () => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        console.log('API key copied to clipboard');
        enqueueSnackbar('API Key copy to clipboard', { variant: 'success' });
      })
      .catch((error) => {
        console.error('Error copying API key:', error);
      });
  };

  const getCustomerDetail = async () => {
    const response = await _detailCustomer();
    if (response.code === 200) {
      setApiKey(response.customer.key);
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const getApiKey = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true);
    const response = await _generateApiKey();
    if (response.code === 200) {
      console.log(response, 'key response');
      setShowPassword(true);
      setApiKey(response.customers.key);
      setIsLoadingBtn(false);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(`Error: ${response.code}`);
      setIsLoadingBtn(false);
    }
  };

  useEffect(() => {
    getCustomerDetail();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Settings </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <div className="d-flex flex-column align-items-center p-5">
              <div
                style={{
                  background: '#0c2e6094',
                  borderRadius: '20px',
                  padding: '15px',
                }}
              >
                <img src={key} alt="logo" width={70} />
              </div>
              <Typography variant="h6" className="mt-5 mb-3">
                API Key
              </Typography>
              {!apiKey ? (
                <CircularProgress />
              ) : (
                <FormControl sx={{ m: 1, width: '400px' }} variant="filled">
                  <FilledInput
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    className="key-input"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        <IconButton className="ms-2" aria-label="copy api key" onClick={handleCopyApiKey} edge="end">
                          <FileCopy />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={apiKey}
                    readOnly
                  />
                </FormControl>
              )}

              <Typography className="mb-5" color={'error'}>
                For security reasons, please do not share it with others.
              </Typography>

              <LoadingButton
                loading={isLoadingBtn}
                loadingPosition="start"
                startIcon={<KeyIcon />}
                variant="outlined"
                onClick={(e) => getApiKey(e)}
              >
                Generate
              </LoadingButton>
            </div>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
