/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import logo from "../../assets/images/logo.png";
import { _emailVerification } from '../../DAL/auth';


const ForgotPassword = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [email, setEmail] = useState("")
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  const handleSendResetLink = async (e) => {
    e.preventDefault();
    setIsLoadingBtn(true)
    const response = await _emailVerification({ email })
    console.log(response, "jkahsdkjashdkjashd")
    if (response.code === 200) {
      setIsLoadingBtn(false)
      localStorage.setItem("user-email", email)
      enqueueSnackbar(response.message, { variant: "success" })
      navigate("/verify-code")
    }
    else {
      setIsLoadingBtn(false)
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: "error" })
    }
  }

  return (
    // 1
    <div className="container-fluid">
      <div className="row" style={{ height: "100vh" }}>
        <div className="col- col-lg-12 col-xl-8 py-4 d-flex flex-column">
          <img src={logo} alt="logo" className="d-block ps-5" width={200} />
          <div
            className="d-flex flex-column align-items-center"
            style={{ padding: "60px 0" }}
          >
            <div className="text-left custom-width w-50 mb-3">
              <a href="/login" className='mb-1'><i className="fa-solid fa-chevron-left fa-2xs" />Go back</a>
              <h1 className="mb-3">Reset password</h1>
              <p className="reset-password-para mb-5">
                We'll email you instructions on how to reset your password.
              </p>
              <form className="form-label" onSubmit={handleSendResetLink}>
                <div className="form-group mb-4">
                  <label className='mb-1 font-weight-bold' htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={isLoadingBtn}
                  loadingPosition="start"
                  startIcon=" "
                  variant="contained"
                  style={{ padding: ".75rem 1.5rem" }}
                >
                  Send verification code
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-4 right-col" />
      </div>
    </div>
  )
}

export default ForgotPassword