/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { _activePlans } from '../../DAL/plans';
import { useAppContext } from '../../hooks/AppContext';

export default function EmailPlans() {
  const { customerDetail } = useAppContext();
  console.log(customerDetail, 'customerrr');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [windowWidth, setWindowWidth] = useState('lg');

  const [plans, setPlans] = useState();

  const handleChangePath = () => {
    navigate('/payment-plans');
  };

  const getActivePlans = async () => {
    setIsLoading(true);
    const response = await _activePlans();
    if (response.code === 200) {
      console.log('active-plans', response);
      setPlans(response.plans);
      const enterpriseId = response.plans.find((plan) => plan.name === 'Enterprise');
      if (enterpriseId && customerDetail?.plan_id === enterpriseId._id) {
        setIsEnterprise(true);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getActivePlans();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Email Plans </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Email Plans
          </Typography>
        </Stack>
        <Card className="p-4 d-flex justify-content-around">
          {isLoading ? (
            <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <div className="row " style={{ minHeight: '100%', maxWidth: '100%' }}>
              {plans &&
                plans.map((val, key) => (
                  <div className="col-12 col-md-4 mb-3" key={key}>
                    <div
                      className="border"
                      style={{ borderRadius: '10px', position: 'relative', height: '100%', maxWidth: '95%' }}
                    >
                      {customerDetail?.plan_id === val._id ? (
                        <div className="ribbon-2">
                          <p className="mb-0">Activated</p>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="d-flex flex-column align-items-center card-header-bg py-4">
                        <p className="primary-color mb-1">{val.name}</p>
                        <h1 className="mb-2 primary-color" style={{ fontSize: '40px' }}>
                          {val.amount === 0 ? 'Free' : `$${val.amount}`}
                        </h1>
                        <p className="fw-bold">{val.daily_email_allowed} emails/month</p>
                        <p className="text-center px-4 mb-4">{val.description}</p>
                        {customerDetail?.plan_id === val._id || val.name === 'Basic' ? (
                          <div style={{ marginBottom: '28px' }} />
                        ) : !isEnterprise ? (
                          <div className="text-center">
                            <Link
                              className="plan-btn"
                              to={`/payment-plans/${val._id}`}
                              // state={{plan:val}}
                            >
                              Upgrade
                            </Link>
                          </div>
                        ) : (
                          <div style={{ marginBottom: '28px' }} />
                        )}
                      </div>
                      <div className="plan-list py-3">
                        <ul className="list-unstyled mb-5">
                          {val?.features?.map((feature, key) => (
                            <li className="d-flex" key={key}>
                              <DoneIcon className="list-icon me-2" color="primary" />
                              {feature.feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {/* {val.name === "Premium" ? (
                    <div className="text-center upgrade-btn my-4">
                      <Link className="plan-btn"
                        to={`/payment-plans/${val._id}`}
                      // state={{plan:val}}
                      >
                        Upgrade to {val.name}
                      </Link>
                    </div>
                  ) : val.name === "Enterprise" ? (
                    <div className="text-center upgrade-btn my-4">
                      <Link className="plan-btn"
                        to={`/payment-plans/${val._id}`}
                      // state={{plan:val}}
                      >
                        Upgrade to {val.name}
                      </Link>
                    </div>
                  ) : ""} */}
                  </div>
                ))}
            </div>
          )}
        </Card>
      </Container>
    </>
  );
}
