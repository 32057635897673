/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import { Avatar, Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { useAppContext } from '../../../hooks/AppContext';
import account from '../../../_mock/account';
import { s3baseUrl } from '../../../config/config';

function Sidebar({ displayComponent, setDisplayComponent }) {
  const { customerDetail } = useAppContext();

  return (
    <>
      <div className="sidebar-profile" style={{ position: 'fixed', boxSizing: 'border-box', paddingRight: '1px' }}>
        <div className="sidebar-account-info">
          <div className="sidebar-account-inner">
            <div className="me-2">
              <Avatar
                sx={{ height: '60px', width: '60px' }}
                src={s3baseUrl + customerDetail?.profile_image || account.photoURL}
                alt={customerDetail?.first_name + customerDetail?.last_name}
              />
            </div>
            <div style={{ my: 1.5, px: 2.5, mt: 3 }}>
              <Typography sx={{ fontSize: '20px' }} variant="subtitle2" noWrap>
                <div className="d-flex">
                  <div className="me-2"> {customerDetail?.first_name}</div>
                  <div>{customerDetail?.last_name}</div>
                </div>
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {customerDetail?.user?.email}
              </Typography>
            </div>
          </div>
        </div>
        <div className="sidebar-nav-info">
          <div
            className="sidebar-navs mb-3 "
            style={{
              backgroundColor: displayComponent === 'accounts' ? '#dfe6f7' : '',
              borderRadius: displayComponent === 'accounts' ? '10px' : '',
              cursor: 'pointer',
            }}
            onClick={() => setDisplayComponent('accounts')}
          >
            <div style={{ paddingLeft: '20px', fontSize: '18px' }}>
              <span className="me-4">
                <Iconify
                  sx={{ color: (theme) => theme.palette.primary.main, width: '25px', height: '25px' }}
                  icon={'mdi:accounts'}
                />
              </span>
              Accounts
            </div>
          </div>
          <div
            className="sidebar-navs mb-3"
            style={{
              backgroundColor: displayComponent === 'security' ? '#dfe6f7' : '',
              borderRadius: displayComponent === 'security' ? '10px' : '',
              cursor: 'pointer',
            }}
            onClick={() => setDisplayComponent('security')}
          >
            <div style={{ paddingLeft: '20px', fontSize: '18px' }}>
              <span className="me-4">
                <Iconify
                  sx={{ color: (theme) => theme.palette.primary.main, width: '25px', height: '25px' }}
                  icon={'material-symbols:lock'}
                />
              </span>
              Security
            </div>
          </div>
          <div
            className="sidebar-navs mb-3"
            style={{
              backgroundColor: displayComponent === 'card' ? '#dfe6f7' : '',
              borderRadius: displayComponent === 'card' ? '10px' : '',
              cursor: 'pointer',
            }}
            onClick={() => setDisplayComponent('card')}
          >
            <div style={{ paddingLeft: '20px', fontSize: '18px' }}>
              <span className="me-4">
                <Iconify
                  sx={{ color: (theme) => theme.palette.primary.main, width: '25px', height: '25px' }}
                  icon={'material-symbols:star-outline'}
                />
              </span>
              Manage Card
            </div>
          </div>
          <div
            className="sidebar-navs mb-3"
            style={{
              backgroundColor: displayComponent === 'subscription' ? '#dfe6f7' : '',
              borderRadius: displayComponent === 'subscription' ? '10px' : '',
              cursor: 'pointer',
            }}
            onClick={() => setDisplayComponent('subscription')}
          >
            <div style={{ paddingLeft: '20px', fontSize: '18px' }}>
              <span className="me-4">
                <Iconify
                  sx={{ color: (theme) => theme.palette.primary.main, width: '25px', height: '25px' }}
                  icon={'solar:card-outline'}
                />
              </span>
              Subscription
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
