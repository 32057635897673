import { invokeApi } from "../utils/invokeApi";

export const _fileUpload = async (data) => {
    const requestObj = {
      path: `api/app_api/uplaod_file_s3_with_dir`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };