import { invokeApi } from "../utils/invokeApi";

export const _sendEmail = async (data) => {
    const requestObj = {
        path: `api/customer/send_email`,
        method: "POST",
        headers: {
            'Content-Type': "multipart/form-data",
            'x-sh-key': localStorage.getItem('key'),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};