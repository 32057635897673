import { invokeApi } from "../utils/invokeApi";

export const _dashboard = async () => {
    const requestObj = {
      path: `api/customer/customer_dashboard`,
      method: "GET",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    };
    return invokeApi(requestObj);
  };

  