/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  CardContent,
  CircularProgress,
  Tooltip,
  Button,
  TableFooter,
  Modal,
  Grid,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { _domainVerification, _getDomainList } from '../../DAL/domain';
import { AppCurrentVisits } from '../../sections/@dashboard/app';
import totalMail from '../../assets/images/email-solid.svg';
import mailSent from '../../assets/images/email-check-outline.svg';
import mailBounce from '../../assets/images/block.svg';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';

// -----------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'record', label: 'Record', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false, center: true },
  { id: 'value', label: 'Value', alignRight: false, center: true },
  { id: 'status', label: 'Status', alignRight: false, center: true },
];

// ------------------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const DomainDetailPage = () => {
  const { state } = useLocation();
  console.log(state, 'domainDetail');
  const { enqueueSnackbar } = useSnackbar();
  const domainId = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const [order, setOrder] = useState('asc');
  const [dataDetail, setDataDetail] = useState(state?.domainDetail);
  console.log(dataDetail, 'datadetailss');
  const [domainList, setDomainList] = useState();

  const [sent, setSent] = useState(87);
  const [bounce, setBounce] = useState(17);
  const [totalSent, setTotalSent] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [copy, setCopy] = useState('Copy');
  const [record, setRecord] = useState({
    mx: false,
    dmarc_txt: false,
    spf_txt: false,
  });

  const getDomainList = async () => {
    try {
      const response = await _getDomainList();
      if (response.code === 200) {
        console.log('domain-list', response.data.domains);
        setDomainList(response.data.domains);
        const val = response?.data?.domains.find((row) => row._id == domainId.id);
        setDataDetail(val);
        if (val?.dns_records) {
          setIsLoadingVerify(false);
        }
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      console.error('Error fetching domain list:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      console.error('domain list');
    }
  };

  const verifyDomain = async (e, name) => {
    e.preventDefault();
    setIsLoadingVerify(true);
    const response = await _domainVerification({ name });
    if (response.code == 200) {
      try {
        setDataDetail(response.data.domain);
        console.log(response.data.domain, 'datadomainss');
        setRecord({
          mx: response.data.MX,
          dmarc_txt: response.data.DMARC_TXT,
          spf_txt: response.data.SPF_TXT,
        });
        setIsLoadingVerify(false);
        if (response.data.verification_status === true) {
          enqueueSnackbar('Domain verified successfully', { variant: 'success' });
        }
        if (response.data.verification_status === false) {
          enqueueSnackbar('Domain can not be verified', { variant: 'error' });
        }
      } catch (error) {
        setIsLoadingVerify(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else {
      setIsLoadingVerify(false);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const handleCopy = (val) => {
    window.navigator.clipboard.writeText(val);
    setCopy('Copied!');
    setTimeout(() => {
      setCopy('Copy');
    }, 2000);
  };

  useEffect(() => {
    getDomainList();
  }, []);

  return (
    <>
      <Helmet>
        <title> Domain Verification </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="column" justifyContent="space-between" mb={5}>
          <div className="d-flex align-items-center">
            <KeyboardReturnIcon
              style={{
                marginRight: '20px',
                background: '#89c7aa',
                padding: '5px',
                borderRadius: '30px',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(-1)}
            />
            <Typography variant="h4" className="mb-0" gutterBottom>
              Domain Verification
            </Typography>
          </div>
        </Stack>
        <Card>
          <div className="px-3 my-5">
            <h5>Instructions:</h5>
            <ol className="instruction-list">
              <li>
                Sign in to your <strong>Domain Name Provider</strong>, Inc.
              </li>
              <li>
                Go to the <strong>Manage</strong> option for the domain.
              </li>
              <li>
                Go to the <strong>DNS settings</strong> for the domain, Inc.
              </li>
              <li>
                Copy and Add the following <strong>DNS Records</strong> in <strong>DNS settings</strong> and then{' '}
                <strong>Verify</strong> your domain.
              </li>
            </ol>
          </div>
          <div className="px-3 mt-5 mb-3 text-center">
            <Typography
              variant="h6"
              style={{
                fontWeight: '900',
              }}
              gutterBottom
            >
              {dataDetail?.name}
            </Typography>
          </div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table>
                  <UserListHead order={order} headLabel={TABLE_HEAD} rowCount={dataDetail?.dns_records?.length} />
                  <TableBody>
                    {console.log(dataDetail, 'dataDetaildataDetaildataDetail')}
                    {dataDetail &&
                      dataDetail?.dns_records?.map((row, index) => (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox">
                          <TableCell align="center">
                            <Typography variant="subtitle2" noWrap>
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{`${row.Type} Record`}</TableCell>

                          <TableCell align="left" onClick={() => handleCopy(row.Name)}>
                            <Tooltip title={copy} placement="top-end">
                              <div className="para-background" style={{ cursor: 'pointer' }}>
                                <p className="para-style">{row.Name}</p>
                                <ContentCopyIcon className="copy-icon" />
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell align="left" onClick={() => handleCopy(row.Value)}>
                            <Tooltip title={copy} placement="top-end">
                              <div className="para-background" style={{ cursor: 'pointer' }}>
                                <p className="para-style">{row.Value}</p>
                                <ContentCopyIcon className="copy-icon" />
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell align="left">
                            <div className="para-background" key={index}>
                              <p className="mb-0">{row.verification_status == false ? 'Not Verified' : 'Verified'}</p>
                              {console.log('row status is ', row.verification_status)}
                              {isLoadingVerify ? (
                                <CircularProgress className="ms-1" size="15px" />
                              ) : row.status == true ? (
                                <DoneIcon style={{ color: 'green', fontSize: '16px' }} />
                              ) : (
                                <CloseIcon style={{ color: 'red', fontSize: '16px' }} />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  {!dataDetail && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          {!isLoading &&
            (dataDetail?.is_default === true ? (
              ''
            ) : (
              <div className="my-3" style={{ textAlign: 'right' }}>
                <Button
                  className="me-4"
                  style={{ width: '120px' }}
                  variant="contained"
                  endIcon={<VerifiedUserIcon />}
                  onClick={(e) => verifyDomain(e, dataDetail.name)}
                  disabled={isLoadingVerify}
                >
                  Verify
                </Button>
              </div>
            ))}
        </Card>

        {dataDetail && dataDetail?.verification_status !== false && (
          <>
            {/* <Box className="w-100 d-flex justify-content-between py-5">
              <Card variant="outlined" sx={{ width: '50%', marginRight: '10px' }}>
                <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                  <img src={totalMail} alt="mail sent" width={100} height={100} />

                  <div>
                    {totalSent !== null ? (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.email_count}</h1>
                    ) : (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                    )}
                    <Typography variant="h4" color={'#454545'}>
                      Total Emails
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ width: '50%', marginRight: '10px' }}>
                <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                  <img src={mailBounce} alt="mail sent" width={100} height={100} />
                  <div>
                    {bounce !== null ? (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.bounced_email_count}</h1>
                    ) : (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                    )}
                    <Typography variant="h4" color={'#454545'}>
                      Emails Bounced
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ width: '50%', marginRLeft: '10px' }}>
                <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                  <img src={mailSent} alt="mail bounced" width={100} height={100} />
                  <div>
                    {sent !== null ? (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.sent_email_count}</h1>
                    ) : (
                      <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                    )}
                    <Typography variant="h4" color={'#454545'}>
                      Total Sent Emails
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Box> */}
            <Grid container spacing={3} className="py-5">
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined">
                  <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                    <img src={totalMail} alt="mail sent" width={100} height={100} />
                    <div>
                      {totalSent !== null ? (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.email_count}</h1>
                      ) : (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                      )}
                      <Typography variant="h4" color={'#454545'}>
                        Total Emails
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined">
                  <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                    <img src={mailBounce} alt="mail sent" width={100} height={100} />
                    <div>
                      {bounce !== null ? (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.bounced_email_count}</h1>
                      ) : (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                      )}
                      <Typography variant="h4" color={'#454545'}>
                        Emails Bounced
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined">
                  <CardContent className="text-center d-flex justify-content-evenly align-items-center">
                    <img src={mailSent} alt="mail bounced" width={100} height={100} />
                    <div>
                      {sent !== null ? (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>{dataDetail?.sent_email_count}</h1>
                      ) : (
                        <h1 style={{ fontSize: '60px', fontWeight: '900' }}>0</h1>
                      )}
                      <Typography variant="h4" color={'#454545'}>
                        Total Sent Emails
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box>
              {dataDetail && (
                <AppCurrentVisits
                  title="Current Statistics"
                  subheader={!(dataDetail.bounced_email_count == 0 && dataDetail.sent_email_count == 0)}
                  chartData={[
                    { label: 'Emails Sent', value: dataDetail?.sent_email_count },
                    { label: 'Emails Bounced', value: dataDetail?.bounced_email_count },
                  ]}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.error.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                  ]}
                />
              )}
            </Box>
          </>
        )}
      </Container>
      {/* modal: domain verification */}
      {/* <div>
				<Modal
					open={openVerificationModal}
					onClose={handleCloseVerificationModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" align='center' color={"primary"} className='mb-3' variant="h5" component="h2">
							Domain Verification
						</Typography>
						<div className='p-4 mb-3'
							style={{
								background: "#e3e3e3",
								borderRadius: "8px",
								border: "1px solid #454545",
								boxShadow: "0px 1px 0px #000000b5",
							}}
						>
							<h5 className='mb-2'>Please Wait, We are verifying the following records:</h5>
							<ul className='list-unstyled record-list'>
								<li>{isLoading ? (
									<CircularProgress className='me-3' size="20px" />
								) : record.mx == true ? (
									<DoneIcon className='me-3 icon-style' />
								) : (
									<CloseIcon className='me-3 icon-style' style={{ color: "#c20909" }} />
								)
								}
									<h6>MX Record</h6>
								</li>
								<li>{isLoading ? (
									<CircularProgress className='me-3' size="20px" />
								) : record.mx == true ? (
									<DoneIcon className='me-3 icon-style' />
								) : (
									<CloseIcon className='me-3 icon-style' style={{ color: "#c20909" }} />
								)
								}
									<h6>TXT Record</h6>
								</li>
								<li>{isLoading ? (
									<CircularProgress className='me-3' size="20px" />
								) : record.mx == true ? (
									<DoneIcon className='me-3 icon-style' />
								) : (
									<CloseIcon className='me-3 icon-style' style={{ color: "#c20909" }} />
								)
								}
									<h6>TXT Record</h6>
								</li>
							</ul>
						</div>
						<p>* Please be aware that it may take up to 24 hours for the records to be fully updated.</p>
						<div className="d-flex justify-content-end mt-4">
							<Button
								variant="contained"
								size='small'
								style={{ width: "120px" }}
								onClick={handleCloseVerificationModal}
							>
								Close
							</Button>
						</div>
					</Box>
				</Modal>
			</div> */}
    </>
  );
};

export default DomainDetailPage;
