import { invokeApi } from "../utils/invokeApi";

export const _generateApiKey = async () => {
    const requestObj = {
      path: `api/customer/renew_key`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    };
    return invokeApi(requestObj);
  };