/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import Profile from 'src/pages/profile/Profile';
// mock
import account from '../../../_mock/account';
// hooks
import { useAppContext } from '../../../hooks/AppContext';
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { s3baseUrl } from '../../../config/config';
import NavConfigSecondary from './secondaryConfig';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const NAV_WIDTH_Collapse = 80;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// -----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { customerDetail, setOpenProfileDialog } = useAppContext();
  const { pathname } = useLocation();

  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      onMouseOver={() => {
        if (isDesktop) {
          setIsMouseEnter(true);
        }
      }}
      onMouseLeave={() => {
        if (isDesktop) {
          setIsMouseEnter(false);
        }
      }}
    >
      <Box
        className="animation-box-LTR"
        sx={{
          height: isDesktop || isMouseEnter ? '100px' : '50px',
          width: isDesktop ? 'auto' : '100%',
          margin: isDesktop ? '' : '0px auto',
          px: 2.5,
          pt: 3,
          display: 'inline-flex',
        }}
      >
        <Logo isMouseEnter={isMouseEnter} isDesktop={isDesktop} />
      </Box>
      <NavSection customclass={'primary-nav'} data={navConfig} isMouseEnter={isMouseEnter} isDesktop={isDesktop} />

      <Box className="w-100 h-100 d-flex align-items-end">
        <NavSection
          customclass={'secondary-nav'}
          data={NavConfigSecondary}
          isMouseEnter={isMouseEnter}
          isDesktop={isDesktop}
        />
      </Box>

      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <div className="sidebar-bottom-profile">
        <div
          className="sidebar-bottom"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpenProfileDialog(true);
          }}
        >
          <div className="profile-sidebar">
            <div className="profile-sidebar-inner">
              <div>
                <Avatar
                  sx={{ height: '50px', width: '50px' }}
                  src={s3baseUrl + customerDetail?.profile_image || account.photoURL}
                  alt={customerDetail?.first_name + customerDetail?.last_name}
                />
              </div>
              <div className="profile-content">
                <div>
                  <Typography sx={{ fontSize: '16px' }} variant="subtitle2" noWrap>
                    <div className="d-flex">
                      <div className="me-2"> {customerDetail?.first_name}</div>
                      <div>{customerDetail?.last_name}</div>
                    </div>
                  </Typography>
                </div>
                <div>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {customerDetail?.user?.email}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Profile />
    </Scrollbar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: isMouseEnter === false ? NAV_WIDTH_Collapse : NAV_WIDTH },
          transition: 'width 0.3s ease-out',
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: isMouseEnter === false ? NAV_WIDTH_Collapse : NAV_WIDTH,
                transition: 'width 0.3s ease-out',
                bgcolor: 'background.default',
                // bgcolor: '#edd5a1',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    </>
  );
}
