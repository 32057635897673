/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogTitle, Divider } from '@mui/material';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { useAppContext } from '../../../hooks/AppContext';
import { _cancelSubscription } from '../../../DAL/transactions';
import { _detailCustomer } from '../../../DAL/customer';

function SubscriptionCard() {
  const { customerDetail, setCustomerDetail } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  console.log(customerDetail, 'detailss');
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] = useState(false);
  const [isLoadingDialogBtn, setIsLoadingDialogBtn] = useState(false);

  const handleCloseDialog = () => {
    setCancelSubscriptionDialog(false);
  };

  const handleOpenDialog = () => {
    setCancelSubscriptionDialog(true);
  };

  const getCustomerDetail = async () => {
    setIsLoading(true);
    const response = await _detailCustomer();
    if (response.code === 200) {
      setIsLoading(false);
      setCustomerDetail(response.customer);
    } else {
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getCustomerDetail();
  }, []);

  const handleCancelSubscription = async () => {
    setIsLoadingDialogBtn(true);
    const id = customerDetail.subscription_id;
    const response = await _cancelSubscription(id);
    if (response.code === 200) {
      setIsLoadingDialogBtn(false);
      handleCloseDialog();
      getCustomerDetail();
      enqueueSnackbar(response.message, { variant: 'success' });
      // setCustomerDetail((prev) => {
      //   return {
      //     ...prev,
      //     subscription_id: '',
      //   };
      // });
    } else {
      setIsLoadingDialogBtn(false);
      enqueueSnackbar(response.message, { variant: 'error' });
      console.log(response.message, 'cancel subscription error');
    }
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <div
          className="security-tab"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {customerDetail && (
            <>
              <h3 className="h2-font-size" style={{ fontWeight: '600' }}>
                Subscription
              </h3>
              <Divider sx={{ color: '#ECECEC' }} className="mb-1" />
              <Card
                className="p-5 m-0 mt-5"
                sx={{
                  boxShadow: '0px 3px 5px 5px rgba(90, 114, 123, 0.03)',
                  p: '10px',
                }}
              >
                <div>
                  <div className="h3-style">
                    <div className="d-flex justify-content-between">
                      <h4>{customerDetail?.plan.name}</h4>
                      {/* <Icon
                    icon="mdi:dots-horizontal"
                    width="24"
                    height="24"
                    className="section-option-icon"
                    onClick={(e) => handleMenuPopup(e)}
                  /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        color: '#beccdb',
                        alignItems: 'center',
                      }}
                    >
                      <h2
                        style={{
                          fontStyle: '32px',
                          color: '#beccdb',
                          fontWeight: 'initial',
                        }}
                      >
                        $
                      </h2>{' '}
                      <h2
                        style={{
                          fontStyle: '32px',
                          fontWeight: 'initial',
                        }}
                      >
                        {/* {plan.amount} */}
                        {customerDetail?.plan.amount}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-3 d-flex justify-content-between" style={{ fontSize: '20px' }}>
                    <span style={{ fontWeight: 'bold' }}>Card:</span>
                    {customerDetail?.card ? <span>**** **** **** {customerDetail?.card}</span> : '-'}
                  </div>
                  <div className="mt-3 d-flex justify-content-between" style={{ fontSize: '20px' }}>
                    <span style={{ fontWeight: 'bold' }}>Subscription Start Date:</span>
                    {customerDetail?.subscription_start_date ? (
                      <span>{moment(customerDetail?.subscription_start_date).format('LL')}</span>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="mt-3 d-flex justify-content-between" style={{ fontSize: '20px' }}>
                    <span style={{ fontWeight: 'bold' }}>Next Renewal Date:</span>
                    {customerDetail?.subscription_next_renewal_date ? (
                      <span>{moment(customerDetail?.subscription_next_renewal_date).format('LL')}</span>
                    ) : (
                      '-'
                    )}
                  </div>
                  {customerDetail?.subscription_id ? (
                    <div style={{ position: 'relative', marginTop: '20px' }}>
                      <LoadingButton
                        style={{ position: 'absolute', right: '0' }}
                        variant="contained"
                        loading={isLoadingBtn}
                        onClick={handleOpenDialog}
                      >
                        Cancel Subscription
                      </LoadingButton>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            </>
          )}
        </div>
      )}

      <div>
        <Dialog
          open={cancelSubscriptionDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to cancel your subscription?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingDialogBtn}
              variant="contained"
              size="small"
              onClick={handleCancelSubscription}
            >
              Yes, Continue
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default SubscriptionCard;
